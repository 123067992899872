import AuctionFactoryAbi from "../abi/auctionFactoryAbi.json";
import AuctionAbi from "../abi/auctionAbi.json";
import LockerAbi from "../abi/lockerAbi.json";
import Erc20Abi from "../abi/erc20.json";
import { web3 as web3Init } from "../connectors/web3modal";
import { NETWORK_ETH, NETWORK_BSC } from "../address";
import { toEth } from "./../utils/conv";

import {
  FACTORY_ADDRESS,
  BSC_FACTORY_ADDRESS,
  BSC_DELFY_LOCKER_ADDRESS,
  DELFY_LOCKER_ADDRESS,
} from "../address";

let web3;

export const auctionFactory = async (network) => {
  let FACTORY_ADD;
  let web3 = await web3Init();

  if (+network === NETWORK_ETH) {
    FACTORY_ADD = FACTORY_ADDRESS;
  } else if (+network === NETWORK_BSC) {
    FACTORY_ADD = BSC_FACTORY_ADDRESS;
  }

  console.log("Network: ", network, "factory:", FACTORY_ADD);

  return new web3.eth.Contract(AuctionFactoryAbi, FACTORY_ADD);
};

export const auctionContract = async (auctionAddress) => {
  web3 = await web3Init();
  try {
    return new web3.eth.Contract(AuctionAbi, auctionAddress);
  } catch (e) {
    return;
  }
};

export const lockerContract = async (network) => {
  web3 = await web3Init();

  let FACTORY_ADD =
    +network === NETWORK_BSC ? BSC_DELFY_LOCKER_ADDRESS : DELFY_LOCKER_ADDRESS;

  return new web3.eth.Contract(LockerAbi, FACTORY_ADD);
};

export const ercContract = async (tokenAddress) => {
  web3 = await web3Init();

  return new web3.eth.Contract(Erc20Abi, tokenAddress);
};

export const getTokenAddress = async (auctionAddress) => {
  web3 = await web3Init();

  let auctionC = await auctionContract(auctionAddress);
  try {
    let tokenAddress = await auctionC.methods.token().call();
    return tokenAddress;
  } catch (error) {
    console.log(error);
  }
};
export const getFee = async (auctionAddress) => {
  web3 = await web3Init();

  let auctionC = await auctionContract(auctionAddress);
  try {
    let createCaseFee = await auctionC.methods.createCaseFee().call();
    let createReFundCaseFees = await auctionC.methods
      .createReFundCaseFees()
      .call();
    let supportCaseFee = await auctionC.methods.supportCaseFee().call();
    let supportRefundCaseFees = await auctionC.methods
      .supportRefundCaseFees()
      .call();

    return {
      createCaseFee: toEth(createCaseFee),
      createReFundCaseFees: toEth(createReFundCaseFees),
      supportCaseFee: toEth(supportCaseFee),
      supportRefundCaseFees: toEth(supportRefundCaseFees),
    };
  } catch (error) {
    console.log(error);
  }
};

export const getTokenDetails = async (auctionAddress, tokenAddress) => {
  web3 = await web3Init();

  let auctionC = await auctionContract(auctionAddress);
  let tokenC = await ercContract(tokenAddress);

  try {
    const logoLink = await auctionC.methods.logoLink().call();
    const tokenName = await tokenC.methods.name().call();

    return {
      name: tokenName,
      logo: logoLink,
      link: auctionAddress,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getAuctionAddress = async (tokenAddress, network) => {
  web3 = await web3Init();

  let auctionFac = await auctionFactory(network);
  try {
    let address = await auctionFac.methods.getAuction(tokenAddress).call();
    return address;
  } catch (error) {
    console.log(error);
  }
};

export const getLockerAddress = async (auctionAddress) => {
  web3 = await web3Init();

  let auctionC = await auctionContract(auctionAddress);
  try {
    let lockerAddress = await auctionC.methods.locker().call();
    return lockerAddress;
  } catch (error) {
    console.log(error);
  }
};

export const getEthBal = async (auctionAddress) => {
  web3 = await web3Init();

  try {
    return await web3.eth.getBalance(auctionAddress);
  } catch (error) {
    console.log(error);
  }
};
