import React, { useCallback } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "./../store";
import { setUser, setNetwork } from "./../features/user/UserSlice";

//  // Web3modal instance
let web3Modal;

//  // Chosen wallet provider given by the dialog window
let provider;

export const web3 = async () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "ae69348d48e148e79799e35958268f01",
      },
    },
  };

  web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
  });
  web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  try {
    provider = await web3Modal.connect();
  } catch (e) {
    console.log("Could not get a wallet connection", e);
    return;
  }
  subscribeToEvents();
  const web33 = new Web3(provider);

  return web33;
};

const subscribeToEvents = async () => {
  provider.on("accountsChanged", (accounts) => {
    store.dispatch(setUser(accounts[0]));
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    console.log(chainId);
  });

  // Subscribe to networkId change
  provider.on("networkChanged", (networkId) => {
    store.dispatch(setNetwork(networkId));
  });

  await refreshAccountData();
};
const ethereum = window.ethereum;
export const refreshAccountData = async () => {
  const networkId = await ethereum.networkVersion;
  const accounts = await ethereum.request({ method: "eth_accounts" });

  if (accounts.length > 0) {
    store.dispatch(setUser(accounts[0]));
    store.dispatch(setNetwork(networkId));
  }
};

// export const refreshAccountData = () => {
//   console.log("account refrehed");
// };

// const provider = await web3Modal.connect();

// const web3 = new Web3(provider);
