import React, { FC, ReactElement } from 'react';
import { Button } from 'antd';
import { web3  } from "./../connectors/web3modal";

const Connect: FC = (): ReactElement => {

    const connect=()=>{
        web3()
    }
    return (
        <div className='connect-card'>
           <p>You are not currently connected</p> 
          <Button  type='primary' shape="round" onClick={connect}  >Connect</Button>
       </div>
    )
}

export default Connect