import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";

import { Button, Space } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

const HomePage: FC = (): ReactElement => {
  return (
    <div>
      <div className='banner'>
        <div className='contain'>
          <div className='left'>
            <h1>No 1 Auction Platform For Crypto Assets</h1>
            <p>
              DelfyAuction offers a secure platform for investor-oriented crypto
              assets sales. No need for delayed developer's actions, since all
              auction processes, including decentralized exchange listing and
              funds withdrawal by the developer, are decided and controlled by
              the investors.
            </p>
            <Space>
              <Button>
                <Link to='/auctions'>Goto Auction</Link>
              </Button>
              <Button href='#hiw' ghost>
                How it works
              </Button>
            </Space>
          </div>
          <div className='right'>
            <img
              width={"100%"}
              src={`${process.env.PUBLIC_URL}/img1.jpg`}
              alt='Deimg'
            />
          </div>
        </div>
      </div>

      <div className='container-home' id='hiw'>
        <div className='section2'>
          <div className='left'>
            <div>
              <img
                width={"100%"}
                src={`${process.env.PUBLIC_URL}/img2.jpg`}
                alt='Delfy'
              />
            </div>
          </div>
          <div className='hiw'>
            <header>
              <h1>How It Works</h1>
            </header>
            <ul>
              <li>
                <p>
                  DelfyAuction simplifies secure and transparent initial token
                  sales for both developers and investors.
                </p>
              </li>
              <li>
                <p>
                  Glitch-free perfectly deployed smart contracts work under the
                  hood synergistically with the intuitive interface to give
                  developers and investors the best sales experience.
                </p>
              </li>
              <li>
                <p>
                  Developers create auctions with specified parameters, and
                  investors can participate in the sales directly from their
                  wallets through Dapp browser.
                </p>
                <p>
                  The case contract protects the investors funds by offering the
                  ability to decide whether the fund is sent to the developer,
                  decentralized exchange or refunded.
                </p>
                <p>
                  All users interact directly with the smart contracts through
                  the Dapp to ensure security and transparency.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='section3'>
        <div className='container-home inner'>
          <div className='head'>
            <header>
              <h1>Why DelfyAuction</h1>
            </header>
            {/* <p>
              DelfyAuction is unique because of the cutting-edge smart contracts
              integrated to proffer features that prioritize the safety of both
              investors' funds and developers' efforts.
            </p> */}
          </div>
          <div className='why-val'>
            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>After-sales Protection</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  Unlike others that only offer sales platforms, DelfyAuction
                  offers up to 7 days protection to the investors after sales,
                  by securing the funds and allowing the investors to decide on
                  the fate of the funds.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Instant Decentralized Exchange Listing with ROI</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  Waiting for developers to send liquidity to decentralized
                  exchange is an obsolescence, since any investor can easily
                  send the initial liquidity to decentralized exchange from the
                  contract.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Locker Contract</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  There is a secure bug-free locker contract that locks
                  liquidity for a specified period.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Investor-Driven Auctions</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  The only action required by the developer is creating the
                  auction. All other actions, from initial exchange launch to
                  the last fund release, are decided and done by the investors.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Stage Protection Case</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  To protect both investors and developers, a case contract is
                  in place where investors can create cases in three different
                  stages during the after-sales protection period. The outcome
                  of these cases determines the movement of the raised funds.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Additional Liquidity and Buyback</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  Investors can create a case and decide that the raised funds
                  should be sent to a decentralized exchange as additional
                  liquidity or used for buybacks of the token from the DEX,
                  rather than releasing it to a bad developer.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Refunding</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  The first time in the history of token sales where investors
                  can get back their invested funds if there is a bug in the
                  token contract or foul play by the developer.
                </p>
              </div>
            </div>

            <div className='why-card'>
              <div className='header'>
                <CheckCircleFilled
                  style={{ color: "#2a2290", fontSize: "18px" }}
                />
                <h4>
                  <b>Decentralized</b>
                </h4>
              </div>
              <div className='body'>
                <p>
                  All the auction processes are done transparently on the
                  blockchain through Dapp, without interacting with anyone.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
