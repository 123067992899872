import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Link, useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "./../features/rootReducers";

import {
  Button,
  Progress,
  Modal,
  Space,
  Spin,
  Input,
  Alert,
  Col,
  Row,
  Form,
  Select,
} from "antd";

import {
  openiNotification,
  openNotificationWithIcon,
} from "./../components/Notify";

import { CloseCircleFilled } from "@ant-design/icons";
import Moment from "react-moment";
import useAuction from "./../hooks/auction";
import useToken from "./../hooks/token";
import useCases from "./../hooks/cases";
import useRelease from "./../hooks/release";
import {
  auctionContract as auctionContractInit,
  lockerContract as lockerContractInit,
  getLockerAddress,
  getEthBal,
  getFee,
} from "./../contracts";

import {
  toWei,
  canWithdraw,
  releaseText,
  enableRelease,
  toEth,
  checkRefund,
} from "./../utils/conv";
import moment from "moment";
import {
  ETHERSCAN_ADDRESS_URL,
  BSC_ADDRESS_URL,
  NETWORK_ETH,
} from "./../address";
// import web3 from "../forHim/web3";

interface RouterProps {
  id: string;
}
interface MatchProps extends RouteComponentProps<RouterProps> {}
const { Option } = Select;
const { TextArea } = Input;

const AuctionDetailsPage: FC<MatchProps> = ({
  match: {
    params: { id: auctionAddress },
  },
}) => {
  const network = useSelector((state: RootState) => state.user.networkId);
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loadingL, setLoadingL] = useState(false);
  const [loadingRf, setLoadingRf] = useState(false);
  const [loadingBurn, setLoadingBurn] = useState(false);
  // const [loadingClaim, setLoadingClaim] = useState(false);
  const [loadingRL, setLoadingRL] = useState(false);
  const [loadingEW, setLoadingEW] = useState(false);
  const [loadingI, setLoadingI] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dToggle, setDtoggle] = useState(false);
  const [declaimer, setDeclaimer] = useState(true);
  const [change, setChange] = useState(false);
  const [launched, setLaunch] = useState(false);
  const [released, setReleased] = useState(false);
  const [changeC, setChangeC] = useState(false);
  const [buy, setBuy] = React.useState("");
  const [hash, setHash] = useState<string>("");
  const account = useSelector((state: RootState) => state.user.address);
  const [issue, setIssue] = React.useState("");
  const [type, setType] = React.useState(0);
  const [claimable, setClaimable] = React.useState(0);
  const [isContributor, setContributor] = React.useState(false);
  const [balance, setBalance] = React.useState({ tBal: "", eBal: "" });
  const [isRefund, setIsRefund] = useState(false);
  // const { lLoading, lError, locker: { lockPeriod, LPBurned, amount } } = useLocker(auctionAddress)
  const { auction, loading, error } = useAuction(auctionAddress, change);
  const { cases0, cases1 } = useCases(auctionAddress, changeC);
  const { release } = useRelease(auctionAddress, changeC);
  const { token } = useToken(auctionAddress);
  const history = useHistory();

  Moment.globalFormat = "D MMM YYYY HH:mm";

  useEffect(() => {
    getAmountClaimable();
    getAmntContributed();
    checkRefund();
  }, [change]);

  useEffect(() => {
    getContributor(account);
  }, [account]);

  const getContributor = async (account): Promise<boolean> => {
    let auctionContract = await auctionContractInit(auctionAddress);
    let contributor = await auctionContract.methods
      .isContributor(account)
      .call();

    setContributor(contributor);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    let auctionContract = await auctionContractInit(auctionAddress);

    auctionContract.methods
      .buyTokenWithEth()
      .send({
        value: toWei(buy.toString()),
        from: account,
      })
      .once("transactionHash", (hash) => {
        setHash(hash);
        setConfirmLoading(false);
        setVisible(false);
        setChange(!change);
        setIssue("");
        openNotificationWithIcon(
          "success",
          "Success",
          `Transaction request to buy ${
            auction && token.tokenName
          } token successful`,
          hash,
        );
      })
      .then((data) => {
        openNotificationWithIcon(
          "success",
          "Success",
          `Transaction request to buy ${
            auction && token.tokenName
          } token successful`,
          hash,
        );
        setVisible(false);
      })
      .catch((error) => {
        setConfirmLoading(false);
        openNotificationWithIcon("error", "Error", "transaction failed", hash);
      });

    // auctionContract.events.Bought(account, toWei(buy.toString()))
    //   .on("data", () => {
    //     setConfirmLoading(false);
    //     setVisible(false);
    //     setChange(!change);
    //   });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleCancelC = () => {
    setToggle(false);
  };

  const handleChange = (e) => {
    setBuy(e.target.value);
  };

  const onChangeType = (value) => {
    setType(value);
  };

  const refundNow = async () => {
    setLoadingRf(true);
    let auctionContract = await auctionContractInit(auctionAddress);
    await auctionContract.methods
      .refundBuyers()
      .send({ from: account })
      .on("transactionHash", (hash) => {
        setHash(hash);
      })
      .on("error", (err, result) => {
        let tHash = result ? result.transactionHash : false;
        openNotificationWithIcon(
          "error",
          "Error",
          "Opps, something went wrong try again",
          tHash,
        );
        setLoadingRf(false);
      })
      .once("receipt", (receipt) => {
        setLoadingRf(false);
        openNotificationWithIcon(
          "success",
          "Success",
          "Refund successfull",
          hash,
        );
      });
  };

  const releaseLiquidity = async () => {
    setLoadingRL(true);
    try {
      let deadline = Math.round(Date.now() / 1000 + 300);
      let auctionContract = await auctionContractInit(auctionAddress);
      await auctionContract.methods
        .releaseLiquidity(deadline)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setLoadingRL(false);
          setChange(!change);
          setReleased(true);
          openNotificationWithIcon(
            "success",
            "Success",
            "Liquidity has been release",
            hash,
          );
        })
        .on("error", (err) => {
          setHash(hash);
          openNotificationWithIcon(
            "error",
            "Error",
            "Opps, something went wrong",
            hash,
          );
        });
    } catch (error) {
      setLoadingRL(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Opps, something went wrong",
        hash,
      );
    }
  };

  const withd = async () => {
    setLoadingEW(true);
    try {
      let auctionContract = await auctionContractInit(auctionAddress);
      await auctionContract.methods
        .withdrawToken()
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err) => {
          setHash(hash);
        });
      openNotificationWithIcon(
        "success",
        "Success",
        "Withdraw successful",
        hash,
      );
      setLoadingEW(false);
      setChange(!change);
    } catch (error) {
      setLoadingEW(false);
      openNotificationWithIcon(
        "error",
        "Error",
        "Opps, something went wrong please try again",
        hash,
      );
    }
  };

  const launchToken = async () => {
    let deadline = Math.round(Date.now() / 1000 + 300);
    let auctionContract = await auctionContractInit(auctionAddress);

    setLoadingL(true);
    try {
      await auctionContract.methods
        .launchEthTokenExchange(deadline)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          openNotificationWithIcon(
            "success",
            "Success",
            "Transaction to launch token on Dx Exchange successful",
            hash,
          );
          setLaunch(true);
          setLoadingL(false);
          setChange(!change);
        })
        .on("error", (err, result) => {
          let tHash = result ? result.transactionHash : false;
          openNotificationWithIcon(
            "error",
            "Error",
            "Opps, something went wrong try again",
            tHash,
          );
          setLoadingL(false);
        });
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Launch Error",
        "Opps, unable to launch token on exchange",
        hash,
      );
      setLoadingL(false);
    }
    // auctionContract.events.SendToDex().on("data", (data) => {
    //   setLoadingL(false);
    //   setChange(!change);
    // });
  };

  const withdrawReserve = async () => {
    let auctionContract = await auctionContractInit(auctionAddress);
    setLoadingL(true);
    try {
      await auctionContract.methods
        .withdrawToken()
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err) => {
          setHash(hash);
        });
      setLoadingL(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Reserve Tokens withdrawal was successful!",
        hash,
      );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Opps something went wrong",
        error.message,
        hash,
      );
      setLoadingL(false);
    }
  };

  const checkBurnable = () =>
    cases0.filter(({ passed }) => passed).length >= 2 ||
    cases0.filter(({ time }) => time > auction.secondRelease).passed === true;

  const burnLiquidityFromAuction = async () => {
    let auctionContract = await auctionContractInit(auctionAddress);

    setLoadingBurn(true);
    try {
      await auctionContract.methods
        .burnInitialLiquidity()
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err) => {
          console.log(err);
          setHash(hash);
        });
      setLoadingBurn(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Liquidity Token Burned!!",
        hash,
      );
    } catch (err) {
      setLoadingBurn(false);
      openNotificationWithIcon(
        "error",
        "Opps something went wrong",
        err.message,
        hash,
      );
    }
  };

  const getAmntContributed = async (): Promise<string> => {
    let auctionContract = await auctionContractInit(auctionAddress);
    let value = await auctionContract.methods.amountContributed(account).call();

    return toEth(value);
  };

  const getClaimable = async (): Promise<boolean> => {
    let auctionContract = await auctionContractInit(auctionAddress);
    let claimable = await auctionContract.methods.getClaimable(account).call();
    return release.isThirdR && parseFloat(toEth(claimable)) > 0;
  };

  const getAmountClaimable = async () => {
    let auctionContract = await auctionContractInit(auctionAddress);
    let eBal = await getEthBal(auctionAddress);
    let claimable = await auctionContract.methods.getClaimable(account).call();
    const getTokenBal = await auctionContract.methods.getTokenBal().call();

    let claim = toEth(claimable);
    setClaimable(claim);
    setBalance({
      eBal: toEth(eBal),
      tBal: toEth(getTokenBal),
    });
  };

  const submitIssue = async () => {
    setLoadingI(true);
    try {
      let auctionContract = await auctionContractInit(auctionAddress);
      let releaseCFees = await auctionContract.methods.createCaseFee().call();
      let refundCFees = await auctionContract.methods
        .createReFundCaseFees()
        .call();
      let feesVal = type === 1 ? releaseCFees : refundCFees;

      console.log("FEESVAL: ", feesVal);
      await auctionContract.methods
        .createTypedCase(issue, type)
        .send({
          value: feesVal.toString(),
          from: account,
        })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err, result) => {
          setHash(hash);
          let tHash = result ? result.transactionHash : false;
          openNotificationWithIcon(
            "error",
            "Error",
            "Opps, something went wrong try again",
            tHash,
          );
          setLoadingI(false);
        });
      setLoadingI(false);
      setToggle(false);
      openNotificationWithIcon("success", "Success", "Case created", hash);
      setChangeC(!changeC);
    } catch (error) {
      setLoadingI(false);
      console.log(error);
    }
  };

  const findPercentage = (numerator, denominator) => {
    return ((+numerator / +denominator) * 100).toFixed(2);
  };
  const compareHadcap = (value) => {
    return Math.round(
      (value.toString() / auction.rate / auction.hardcap) * 100,
    );
  };

  const checkRefund = async () => {
    let auctionContract = await auctionContractInit(auctionAddress);
    let exchangeLaunched = await auctionContract.methods
      .exchangeLaunched()
      .call();
    let salesCompletedTime = await auctionContract.methods
      .salesCompletedTime()
      .call();

    let refund = exchangeLaunched && Date.now() > salesCompletedTime + 691200;
    setIsRefund(refund);
  };

  const handleOkC = () => {
    let charge = getFee(auctionAddress);
    openiNotification(submitIssue, charge, type);
  };

  const onChange = (e) => {
    setIssue(e.target.value);
  };

  return (
    <div className='auction-page' style={{ margin: "10px" }}>
      {declaimer && (
        <div className='declaimer'>
          <div className='cancel' onClick={() => setDeclaimer(false)}>
            <CloseCircleFilled />
          </div>
          <b>
            The information disclosed on this website does not constitute any
            form of financial advice. The Delfy and DelfyAuction team does not
            endorse any{" "}
            {!dToggle && (
              <span
                onClick={() => setDtoggle(true)}
                style={{ color: "#502323" }}>
                ... Show More
              </span>
            )}
            {dToggle && (
              <>
                cryptocurrency investments and it is critical for the investor
                to conduct their own due diligence. Using DelfyAuction does not
                grant you any legal rights against Delfy and DelfyAuction team.
                By using DelfyAuction and other Delfy platforms, you agree to
                hold the team harmless and not liable for any losses or taxes
                you may incur. The platform utilizes and interacts with smart
                contracts that poses a significant risk to all users, you are
                solely responsible for the safety of your funds at all times.
                You also agree that the team is presenting DelfyAuction and
                other Delfy platforms “as is” and is not legally required to
                provide any support or services. Always make sure that you are
                in compliance with your local laws and regulations.
              </>
            )}
          </b>
        </div>
      )}
      <Row gutter={16} style={{ justifyContent: "center" }}>
        {/* <Col xs={24} sm={6} md={6} lg={6}></Col> */}
        <Col style={{ marginTop: "5%" }} xs={24} sm={8} md={8} lg={8}>
          <div className='auction-details' style={{ marginBottom: "10px" }}>
            <div>
              {cases0.length || cases1.length > 0 ? (
                <div className='warning'>
                  <p>There is one or more active case on this auction </p>
                  <Link to={"/cases/" + auctionAddress}>
                    <Button shape='round' type='primary'>
                      View all cases
                    </Button>
                  </Link>
                </div>
              ) : (
                <div>
                  <Alert
                    message='Empty Case'
                    description='No case has been published on this auction'
                    type='info'
                    showIcon
                    action={
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => setToggle(true)}>
                        Publish Case
                      </Button>
                    }
                  />
                </div>
              )}
            </div>
            <div className='locker'>
              <Button type='primary' shape='round'>
                <Link to={`/locker/${auctionAddress}`}>
                  View locker Information
                </Link>
              </Button>
            </div>
          </div>
          <Modal
            title='Publish a new Case'
            visible={toggle}
            onOk={handleOkC}
            confirmLoading={loadingI}
            onCancel={handleCancelC}
            okText='Publish'>
            <Form name='customized_form_controls' layout='vertical'>
              <Form.Item
                tooltip='creating a case requires a fee which may be refunded to you, release to the auction creator or to the exchange as liduidity based on the outcome of the  case and the case type.'
                rules={[{ required: true, message: "Issue cannot be empty" }]}
                name='issue'
                label='Issue'>
                <TextArea
                  placeholder='Protect your investment here...'
                  allowClear
                  onChange={onChange}
                  value={issue}
                  autoSize={{
                    minRows: 3,
                  }}
                />
              </Form.Item>
              <Form.Item
                tooltip={`A fee is required to publish a case and to support the case, be sure you know what you are doing.`}
                rules={[
                  { required: true, message: "Please select a case type" },
                ]}
                name='type'
                label='Select a case type'
                style={{ marginLeft: "10px" }}>
                <Select placeholder='Select type' onSelect={onChangeType}>
                  <Option value={1}>Release Liquidity to Exchange</Option>
                  <Option value={0}>Seize Liquidity for Refund</Option>
                </Select>
              </Form.Item>
            </Form>
          </Modal>
          <div>
            <div className='locker'>
              <div>
                <div className='balance'>
                  <div>
                    <b>Token Balance:</b>{" "}
                    <strong>
                      {" "}
                      {parseFloat(balance.tBal).toFixed(4) || 0} {token.symbol}{" "}
                      {/* {balance.tBal} */}
                    </strong>
                  </div>
                  <div>
                    <b>
                      {+network === NETWORK_ETH ? "Ethereum" : "BNB"} Balance:
                    </b>{" "}
                    <strong>
                      {" "}
                      {parseFloat(balance.eBal).toFixed(4) || "0"}{" "}
                      {+network === NETWORK_ETH ? "ETH" : "BNB"}
                    </strong>
                  </div>
                </div>

                {claimable > 0 && balance.eBal && (
                  <div className='claimabel-con'>
                    <h2>
                      <b>Claimable:</b>{" "}
                      {isContributor && claimable > 0 ? claimable : 0}
                      {+network === NETWORK_ETH ? "ETH" : "BNB"}
                    </h2>
                  </div>
                )}
              </div>
              <Button
                disabled={!isRefund}
                type='primary'
                onClick={refundNow}
                shape='round'>
                Refund Me
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12}>
          <div className='auction-details'>
            <header>
              <h1>Auction Details</h1>
            </header>
            {loading ? (
              <Space
                size='middle'
                style={{ display: "flex", justifyContent: "center" }}>
                <Spin size='large' />
              </Space>
            ) : account && account.length < 1 ? (
              <Button shape='round' type='primary'>
                Connect Your Account
              </Button>
            ) : error ? (
              <Alert
                message='Invalid Address'
                description={error}
                type='error'
                showIcon
              />
            ) : (
              <div>
                <div className='detail-head'>
                  <Button type='primary' shape='round' size='middle' ghost>
                    <a
                      href={
                        (+network === NETWORK_ETH
                          ? ETHERSCAN_ADDRESS_URL
                          : BSC_ADDRESS_URL) + auctionAddress
                      }
                      target='blank'>
                      {auctionAddress.substr(0, 5)} ...{" "}
                      {auctionAddress.substr(-5)}{" "}
                    </a>
                  </Button>
                  {auction.exchangeLaunched ? (
                    <Button
                      type='primary'
                      onClick={burnLiquidityFromAuction}
                      shape='round'
                      disabled={!checkBurnable()}
                      loading={loadingBurn}>
                      Burn LP Token
                    </Button>
                  ) : auction.salesCompleted && !auction.exchangeLaunched ? (
                    <Button
                      type='primary'
                      onClick={launchToken}
                      shape='round'
                      loading={loadingL}
                      disabled={launched}>
                      Launch Exchange
                    </Button>
                  ) : (
                    !auction.salesCompleted && (
                      <Button
                        onClick={showModal}
                        size='middle'
                        type='primary'
                        disabled={
                          auction.salesCompleted ||
                          +auction.availableTokenForSale === 0
                        }
                        shape='round'>
                        Buy Token
                      </Button>
                    )
                  )}

                  {auction && (
                    <Button
                      onClick={releaseLiquidity}
                      type='primary'
                      shape='round'
                      size='middle'
                      loading={loadingRL}
                      disabled={
                        !enableRelease(
                          release.firstRelease,
                          release.secondRelease,
                          release.thirdRelease,
                          release.isFirstR,
                          release.isSecondR,
                          release.isThirdR,
                          auction.ethBal,
                          auction.exchangeLaunched,
                        ) || released
                      }>
                      Release Liquidity
                    </Button>
                  )}
                  {}
                  {/* {checkRefund(
                    cases0,
                    auction.contributorsCount,
                    release.thirdRelease,
                  ) && (
                    <Button
                      onClick={refundNow}
                      type='primary'
                      shape='round'
                      size='middle'
                      loading={loadingRf}
                      disabled={false}>
                      Refund
                    </Button>
                  )} */}

                  {canWithdraw(
                    release.thirdRelease,
                    auction.getTokenBal,
                    auction.exchangeLaunched,
                  ) && (
                    <Button
                      onClick={withd}
                      type='primary'
                      shape='round'
                      loading={loadingEW}
                      disabled={auction.salesCompleted}>
                      Withdraw token
                    </Button>
                  )}
                </div>
                <div className='pro'>
                  <Progress
                    type='circle'
                    percent={auction && auction.progress}
                    width={60}></Progress>
                  <h2 style={{ color: "#000000" }}>
                    Amount Raised {auction && auction.amountRaised}{" "}
                    {+network === NETWORK_ETH ? "ETH" : "BNB"}
                  </h2>
                  <h3>
                    <b>Closes on: </b>
                    <Moment fromNow>
                      {new Date(+auction.openTill * 1000)}
                    </Moment>
                  </h3>
                </div>
                <div className='info-con'>
                  {/* <div className='info'>
                    <h2>Total Supply</h2>
                    <div>
                      {" "}
                      {auction && parseFloat(token.totalSupply).toFixed(4)}
                    </div>
                  </div>{" "}
                  <Divider style={{ padding: 0 }} /> */}
                  <div className='info'>
                    <h2>Total amount Deposited</h2>
                    <div>
                      {" "}
                      {auction &&
                        `${findPercentage(
                          auction.amountToken,
                          token.totalSupply,
                        )}% of Total Supply`}
                    </div>
                  </div>{" "}
                  <hr />
                  <div className='info'>
                    <h2>Initial Liquidity Value</h2>
                    <div>
                      {" "}
                      {auction &&
                        `${compareHadcap(
                          toEth(auction.initialLiquidity),
                        )}% of HC`}
                    </div>
                  </div>
                  <hr />
                  <div className='info'>
                    <h2>
                      Hardcap ({+network === NETWORK_ETH ? "ETH" : "BNB"})
                    </h2>
                    <div>
                      {auction && parseFloat(auction.hardcap).toFixed(4)}{" "}
                      {+network === NETWORK_ETH ? "ETH" : "BNB"}
                    </div>
                  </div>
                  <hr />
                  <div className='info'>
                    <h2>Return on Investment</h2>
                    <div> {auction && auction.roi} %</div>
                  </div>
                  <hr />
                  <div className='info'>
                    <h2>Rate</h2>
                    <div> {auction && auction.rate} </div>
                  </div>{" "}
                  <hr />
                  <div className='info'>
                    <h2>Available {token.symbol} for sale</h2>
                    <div>
                      {auction && auction.availableTokenForSale} {token.symbol}
                    </div>
                  </div>
                </div>{" "}
                {/* <hr/>
                <div className='info'>
                  <h2>Burn Liquidity</h2>
                  <div>
                    {auction && auction.burnLiquidityToken ? "True" : "False"}{" "}
                  </div>
                </div> */}
                <Modal
                  title={`Buy ${token.symbol} token`}
                  visible={visible}
                  onOk={handleOk}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                  okText='Buy'>
                  <div className='buyWithEth'>
                    <h4>
                      Min Buy: {toEth(auction.minimumPurchaseEth)}{" "}
                      {+network === NETWORK_ETH ? "ETH" : "BNB"}
                    </h4>
                    <h4>
                      Max Buy:{" "}
                      {parseFloat(toEth(auction.maximumPurchaseEth)) > 0
                        ? parseFloat(toEth(auction.maximumPurchaseEth))
                        : parseFloat(auction.hardcap).toFixed(4)}{" "}
                      {+network === NETWORK_ETH ? "ETH" : "BNB"}
                    </h4>
                  </div>
                  <form>
                    <div className='c-input'>
                      <input
                        className='input'
                        type='number'
                        placeholder='0.0'
                        onChange={handleChange}
                        value={buy}
                        step='any'
                      />
                    </div>
                  </form>
                </Modal>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuctionDetailsPage;
