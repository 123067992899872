import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    address:'',
    networkId:97
    // 97
  },
  reducers: {
    setUser: (state,{payload}) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    
      state.address=payload;
      
    },
    setNetwork:(state,{payload}) => {
      state.networkId=payload;
      
    },

  }
})


export const { setUser,setNetwork } = userSlice.actions


export default userSlice.reducer
