import { useEffect,useState,useCallback} from 'react'
import { auctionContract as auctionContractInit } from './../contracts'

const useAuction= (auctionAddress,change)=>{
   const [loading,setLoading]=useState(true)   
   const [error,setError]=useState(false)   
   const [canCreateCase,setCanCreateCase]=useState(false)   
   const [caseCreated,setCaseCreated]=useState(false)   
   const [cases0, set0Cases] = useState<any>([]);
   const [cases1, set1Cases] = useState<any>([]);
   const [contributorsCount, setcontributorsCount] = useState<number>(0);
  
   useEffect(() => {
	const getCases=async ()=>{ 
		setLoading(true)
		  try {
			let auctionContract=await auctionContractInit(auctionAddress);
			let case0 = await auctionContract.methods.viewCases(0).call();
			let case1 = await auctionContract.methods.viewCases(1).call();
			const contributorsCount = await auctionContract.methods.contributorsCount().call();
			const canCreateCase = await auctionContract.methods.canCreateCase().call();
			const caseCreated = await auctionContract.methods.caseCreated().call();
			set0Cases(case0);
			set1Cases(case1);
			setcontributorsCount(contributorsCount)
			setCanCreateCase(canCreateCase)
			setCaseCreated(caseCreated)
			setLoading(false)
		  } catch (error) {
			setError(error)
			setLoading(false)
		  }
	  }
      getCases()
   }, [auctionAddress,change])

   return {
    loadingC:loading,
    errorC:error,
	cases0,
	cases1,
	contributorsCount,
	canCreateCase,
	caseCreated
   }
  }

  export default useAuction
