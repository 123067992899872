import React, { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import {
  DEX_ROUTER,
  BSC_DEX_ROUTER,
  EXCHANGE_BSC,
  EXCHANGE_ETH,
  EXCHANGE_ETH_TEST,
  EXCHANGE_BSC_TEST,
  NETWORK_ETH,
} from "./../address";
import { toWei } from "../utils/conv";
import { addTime } from "./../utils/conv";
import moment from "moment";
import { openNotificationWithIcon } from "./../components/Notify";

import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Col,
  Row,
  Modal,
} from "antd";
import { useHistory } from "react-router-dom";
import { RootState } from "../features/rootReducers";
import { auctionFactory } from "./../contracts";
const { Option } = Select;

interface FormValue {
  tokenAddress: string;
  sellingRate: number;
  roi: number;
  burnLiquidityToken?: boolean;
  imageUrl: string;
  salesPeriodDay: number;
  salesPeriodHour;
  lockPeriod: number;
}

const CreateAuction: FC<FormValue> = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<FormValue>({
    tokenAddress: "",
    sellingRate: 0,
    roi: 0,
    salesPeriodDay: 0,
    salesPeriodHour: 0,
    imageUrl: "",
    lockPeriod: 0,
  });
  const history = useHistory();
  const address = useSelector((state: RootState) => state.user.address);
  const network = useSelector((state: RootState) => state.user.networkId);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (values: any, exAddress: []) => {
    let x = +moment();
    let y = moment()
      .add({ days: values.salesPeriodDay, hour: values.salesPeriodHour })
      .subtract(x);
    let salesPeriod = moment(y).format("X");
    let burnLiquidityToken =
      values.lockPeriod === addTime(1000, "years") ? true : false;
    setLoading(true);
    let auctionF = await auctionFactory(network);
    let router = +network === NETWORK_ETH ? DEX_ROUTER : BSC_DEX_ROUTER;
    try {
      const determineForever: boolean =
        values.lockPeriod === addTime(1000, "years") ? true : false;
      await auctionF.methods
        .createAuction(
          exAddress,
          salesPeriod,
          values.tokenAddress,
          toWei(values.sellingRate.toString()),
          values.roi,
          values.lockPeriod,
          burnLiquidityToken,
        )
        .send({ from: address.toString() });
      // .on('receipt',(hash)=>{
      let url = `/setup/${values.tokenAddress}`;
      openNotificationWithIcon(
        "success",
        "Auction Success",
        "You have successfully created an auction",
      );

      history.push(url);
      setLoading(false);
      // })
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "Failed Auction Error",
        "transaction encountered error",
      );
      setLoading(false);
    }
  };

  const onFinishFailed = ({
    values,
    errorFields,
    outOfDate,
  }: {
    values: any;
    errorFields: any;
    outOfDate: any;
  }) => {
    // openNotificationWithIcon()
  };

  const showExchange = (dataPased) => {
    setVisible(true);
    setData(dataPased);
  };

  const handleAddress = (exAddress: []) => {
    setVisible(false);
    onFinish(data, exAddress);
  };

  return (
    <div className='auction-form-con'>
      <div style={{ margin: "20px" }}>
        <header>
          <h1>Create Auction</h1>
        </header>
        <div className={loading ? "overlay" : ""}></div>
        <div className='form'>
          <Form
            name='customized_form_controls'
            layout='vertical'
            onFinish={showExchange}
            onFinishFailed={onFinishFailed}
            initialValues={{
              tokenAddress: "",
              sellingRate: "",
              roi: "",
              burnLiquidityToken: false,
              imageUrl: "",
            }}>
            <Row>
              <Col span={12}>
                <Form.Item
                  tooltip='Maximum period for the sales to be active.'
                  rules={[
                    {
                      required: true,
                      message: "Please select your sales period in days",
                    },
                  ]}
                  name='salesPeriodDay'
                  label='Sales period Days'>
                  <Select placeholder='Days' size='large'>
                    <Option value={0}>0 Day</Option>
                    <Option value={1}>1 Day</Option>
                    <Option value={2}>2 Days</Option>
                    <Option value={3}>3 Days</Option>
                    <Option value={4}>4 Days</Option>
                    <Option value={5}>5 Days</Option>
                    <Option value={6}>6 Days</Option>
                    <Option value={7}>7 Days</Option>
                    <Option value={8}>8 Days</Option>
                    <Option value={9}>9 Days</Option>
                    <Option value={10}>10 Days</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  tooltip=' Maximum period for the sales to be active.'
                  rules={[
                    {
                      required: true,
                      message: "Please select your sales period",
                    },
                  ]}
                  name='salesPeriodHour'
                  label='Sales period Hour'>
                  <Select
                    placeholder='Hours'
                    size='large'
                    style={{ width: "100%", marginLeft: "10px" }}>
                    <Option value={0}>0 Hour</Option>
                    <Option value={6}>6 Hours</Option>
                    <Option value={12}>12 Hours</Option>
                    <Option value={18}>18 Hours</Option>
                    <Option value={23}>23 Hours</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name='tokenAddress'
              rules={[
                { required: true, message: "Token address is required!" },
              ]}
              tooltip='Contract address of the token for sale.'
              label='Token address'>
              <Input placeholder='0xf3490y9q829y9re4283fbd0...' size='large' />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  tooltip='Amount of token per ETH'
                  name='sellingRate'
                  rules={[
                    { required: true, message: "Selling rate is requred" },
                  ]}
                  label='Selling rate'>
                  <InputNumber
                    style={{ width: "100%" }}
                    step='any'
                    placeholder='Your selling rate'
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  tooltip='Percentage return on investment of the sales after first exchange launch.'
                  name='roi'
                  rules={[{ required: true, message: "Field Required" }]}
                  label='ROI'>
                  <InputNumber
                    style={{ width: "100%", marginLeft: "10px" }}
                    placeholder='Roi'
                    size='large'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              tooltip='Duration for initial liquidity lock.'
              name='lockPeriod'
              label='Lock period'>
              <Select
                placeholder='Select Lock Period'
                style={{ width: "100%" }}
                size='large'>
                <Option value={addTime(3, "months")}>3 Month</Option>
                <Option value={addTime(6, "months")}>6 Month</Option>
                <Option value={addTime(9, "months")}>9 Month</Option>
                <Option value={addTime(1, "year")}>1 Year</Option>
                <Option value={addTime(2, "year")}>2 Year</Option>
                <Option value={addTime(3, "year")}>3 Year</Option>
                <Option value={addTime(4, "year")}>4 Year</Option>
                <Option value={addTime(5, "years")}>5 Years</Option>
                <Option value={addTime(10, "years")}>10 Years</Option>
                <Option value={addTime(15, "years")}>15 Years</Option>
                <Option value={addTime(20, "years")}>20 Years</Option>
                <Option value={addTime(25, "years")}>25 Years</Option>
                <Option value={addTime(30, "years")}>30 Years</Option>
                <Option value={addTime(50, "years")}>50 Years</Option>
                <Option value={addTime(1000, "years")}>Forever</Option>
              </Select>
            </Form.Item>

            {/* <Form.Item
							tooltip="Info about liquidity forever"
							name="burnLiquidityToken"
							valuePropName="checked"
						>
							<Checkbox>Burn Liquidity Token</Checkbox>
						</Form.Item> */}
            <Form.Item>
              <Button
                loading={loading}
                type='primary'
                shape='round'
                htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Modal
        title='Select Exchange'
        visible={visible}
        footer={() => <></>}
        onCancel={handleCancel}>
        <div className='exchange-modal'>
          {+network === NETWORK_ETH
            ? EXCHANGE_ETH_TEST.map(({ name, logo, addresses }, i) => (
                <div
                  key={i}
                  className='items'
                  onClick={() => handleAddress(addresses)}>
                  <img src={logo} alt='Logo' />
                  <strong>{name}</strong>
                </div>
              ))
            : EXCHANGE_BSC.map(({ name, logo, addresses }, i) => (
                <div
                  className='items'
                  key={i}
                  onClick={() => handleAddress(addresses)}>
                  <img src={logo} alt='Logo' />
                  <strong>{name}</strong>
                </div>
              ))}
        </div>
      </Modal>
    </div>
  );
};

export default CreateAuction;
