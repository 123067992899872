import React, { FC } from "react";
import { Button, Image } from "antd";
import {
  MediumOutlined,
  TwitterSquareFilled,
  RobotOutlined,
  GithubFilled,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

const Footer: FC = () => {
  return (
    <div className='footer'>
      <div className='container-home'>
        <div className='icons'>
          <a href='https://twitter.com/DelfyGov?s=08' target='blank'>
            <TwitterSquareFilled />
          </a>
          <a href='https://github.com/Delfyfinance' target='blank'>
            <GithubFilled />
          </a>
          <a href='https://delfyfinance.medium.com/' target='blank'>
            <MediumOutlined />
          </a>
          <a
            style={{ marginRight: "10px" }}
            href='https://t.me/delfy_auction'
            target='blank'>
            <i className='fab fa-telegram fa-2x'></i>
          </a>
          <a href='https://t.me/delfyfinance' target='blank'>
            <i className='fab fa-telegram fa-2x'></i>
          </a>
          {/* <a href="https://t.me/delfy_auction/" target="blank"><i className='fab fa-telegram fa-2x'></i></a> */}
          <RobotOutlined />
        </div>
        <p>
          DelfyAuction offers a secure platform for investor-oriented crypto
          assets sales. No need for delayed developer's actions, since all
          auction processes, including decentralized exchange listing and funds
          withdrawal by the developer, are decided and controlled by the
          investors.
        </p>
      </div>
    </div>
  );
};

export default Footer;
