import React, { FC, ReactElement, useState } from "react";

import useCases from "./../hooks/cases";
import useToken from "./../hooks/token";

import { useSelector } from "react-redux";
import { CaseCard } from "./../components";
import { RouteComponentProps } from "react-router";
import { RootState } from "./../features/rootReducers";

import {
  openNotificationWithIcon,
  openiNotification,
} from "./../components/Notify";
import { auctionContract as auctionContractInit, getFee } from "./../contracts";
import { toWei } from "./../utils/conv";
import { Link } from "react-router-dom";

import { Button, Modal, Space, Spin, Alert, Input, Form, Select } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

interface RouterProps {
  id: string;
}
interface MatchProps extends RouteComponentProps<RouterProps> {}

const AuctionCasePage: FC<MatchProps> = ({
  match: {
    params: { id: auctionAddress },
  },
}): ReactElement => {
  const [changeC, setChangeC] = useState(false);
  const [toggle, setToggle] = useState(false);
  const account = useSelector((state: RootState) => state.user.address);
  const { errorC, loadingC, cases0, cases1, contributorsCount } = useCases(
    auctionAddress,
    changeC,
  );
  const { token } = useToken(auctionAddress);
  const [hash, setHash] = useState<string>("");
  const [visible, setVisible] = useState(false);
  const [issue, setIssue] = useState("");
  const [type, setType] = useState(0);
  const [caseDetails, setCaseDetails] = useState("");
  const [loadingI, setLoadingI] = useState(false);

  const onChange = (e) => {
    setIssue(e.target.value);
  };

  const onChangeType = (value) => {
    setType(value);
  };

  const handleOkC = async () => {
    let charge = getFee(auctionAddress);
    openiNotification(submitIssue, charge, type);
  };

  const handleCancelC = () => {
    setToggle(false);
  };

  const handleUpvote = async (type) => {
    try {
      let auctionContract = await auctionContractInit(auctionAddress);
      let releaseVoteFees = await auctionContract.methods
        .supportCaseFee()
        .call();
      let refundVoteFees = await auctionContract.methods
        .supportRefundCaseFees()
        .call();
      let feesVal = type === 1 ? releaseVoteFees : refundVoteFees;

      await auctionContract.methods
        .upvoteCase(type)
        .send({
          value: feesVal.toString(),
          from: account,
        })
        .on("transactionHash", (hash) => {
          setHash(hash);
          openNotificationWithIcon("success", "Success", "Case upvoted", hash);
        });

      setChangeC(!changeC);
    } catch (error) {
      openNotificationWithIcon("error", "Error", error.message, hash);
    }
  };

  const submitIssue = async () => {
    setLoadingI(true);
    try {
      let auctionContract = await auctionContractInit(auctionAddress);
      let releaseCFees = await auctionContract.methods.createCaseFee().call();
      let refundCFees = await auctionContract.methods
        .createReFundCaseFees()
        .call();
      let feesVal = type === 1 ? releaseCFees : refundCFees;
      console.log("FEESVAL: ", feesVal);
      await auctionContract.methods
        .createTypedCase(issue, type)
        .send({
          value: feesVal.toString(),
          from: account,
        })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err, result) => {
          let tHash = result ? result.transactionHash : false;
          openNotificationWithIcon(
            "error",
            "Error",
            "Opps, something went wrong try again",
            tHash,
          );
          setLoadingI(false);
        });
      setLoadingI(false);
      setToggle(false);
      setIssue("");
      openNotificationWithIcon("success", "Success", "Case created", hash);
      setChangeC(!changeC);
    } catch (error) {
      setLoadingI(false);
      // openNotificationWithIcon("error", "Error",'Opps, something went wrong try again', hash);
    }
  };

  const showMore = (issue) => {
    setCaseDetails(issue);
  };

  return (
    <div className='case-page'>
      <div style={{ margin: "10px" }}>
        {/* <div className='cases-con'> */}
        <div className='back' style={{ fontSize: "24px" }}>
          <Link to={`/auction/${auctionAddress}`}>
            <ArrowLeftOutlined />
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}>
          <header>
            <h1>Cases</h1>
          </header>
          <Button
            style={{ marginTop: "10px" }}
            onClick={() => setToggle(true)}
            type='primary'
            shape='round'
            ghost>
            Publish a new Case
          </Button>
        </div>

        {loadingC ? (
          <Space
            size='middle'
            style={{ display: "flex", justifyContent: "center" }}>
            <Spin size='default' />
          </Space>
        ) : (
          <div className='case-grid'>
            <div className='case-con'>
              <h1>Release cases</h1>
              {cases1.length > 0 || cases0.length > 0 ? (
                cases1.map((issue) => (
                  <CaseCard
                    key={issue.index}
                    issue={issue.reason}
                    index={issue.index}
                    symbol={token.symbol}
                    upvoteCount={(issue.upvotes / contributorsCount) * 100}
                    handleUpvote={handleUpvote}
                    balanceOfToken={issue.balanceOfToken}
                    showMore={showMore}
                    caseType={1}
                    getFee={() => getFee(auctionAddress)}
                  />
                ))
              ) : (
                <>
                  <Alert
                    message='Empty Case'
                    description='No case has been created on this auction'
                    type='info'
                    showIcon
                  />
                </>
              )}
            </div>

            <div className='case-con'>
              <h1>Refund cases</h1>
              {cases0.map((issue) => (
                <CaseCard
                  key={issue.index}
                  issue={issue.reason}
                  index={issue.index}
                  symbol={token.symbol}
                  upvoteCount={(issue.upvotes / contributorsCount) * 100}
                  handleUpvote={handleUpvote}
                  balanceOfToken={issue.balanceOfToken}
                  showMore={showMore}
                  caseType={0}
                  getFee={() => getFee(auctionAddress)}
                />
              ))}
            </div>
          </div>
        )}
        {/* </div> */}
      </div>
      <Modal
        title='Case'
        visible={!!caseDetails}
        onCancel={() => {
          setCaseDetails(null);
        }}
        footer={null}>
        <p>{caseDetails}</p>
      </Modal>

      <Modal
        title='Create new Case'
        visible={toggle}
        onOk={handleOkC}
        confirmLoading={loadingI}
        autoSize={{
          minRows: 4,
        }}
        onCancel={handleCancelC}
        okText='Submit'>
        <Form name='customized_form_controls' layout='vertical'>
          <Form.Item
            tooltip='Be clear, thoughtful and be sure you know the implications of your action on your investment.'
            rules={[{ required: true, message: "Issue cannot be empty" }]}
            name='issue'
            label='Issue'>
            <TextArea
              placeholder='What are your issues? '
              allowClear
              onChange={onChange}
              value={issue}
              autoSize={{
                minRows: 3,
              }}
            />
          </Form.Item>
          <Form.Item
            tooltip='case type "release liquidity to exchange" will allow the progress of this auction and case type "seize liquidity for refund" will stop every funds release action for refund purpose. be sure u understand how it works.'
            rules={[{ required: true, message: "Please select case type" }]}
            name='type'
            label='Case type'
            style={{ marginLeft: "10px" }}>
            <Select placeholder='Select type' onSelect={onChangeType}>
              <Option value={1}>Release Liquidity to Exchange</Option>
              <Option value={0}>Seize Liquidity for Refund</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AuctionCasePage;
