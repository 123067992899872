import React, { FC, ReactElement, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Button, Space, Spin, Alert, Select, Image, Input } from "antd";
import { RootState } from "../features/rootReducers";
import { AuctionCard, EmptyAuction } from "../components";
import { Row } from "antd";
import ReactPaginate from "react-paginate";
import AuctionAbi from "../abi/auctionAbi.json";
import { Link } from "react-router-dom";
import { auctionFactory as auctionFac, getTokenDetails } from "./../contracts";
import { web3 as web3Init } from "../connectors/web3modal";

const AuctionPage: FC = (): ReactElement => {
  interface AuctionInt {
    id?: number;
    auctionAddress?: string;
    roi?: string;
    rate?: number;
    amountRaised?: number;
    salesCompleted?: boolean;
    salesStarted?: boolean;
    openPeriod?: any;
    deposited?: number;
  }
  const { Option } = Select;
  const { Search } = Input;
  // const auctions =useSelector((state:RootState)=>state.auctions)
  const [limit, setLimit] = useState(21);
  const [loading, setLoading] = useState(true);
  const [loadingS, setLoadingS] = useState(false);
  const [auctions, setAuctions] = useState<AuctionInt[]>([]);
  const [sAuction, setSAuction] = useState(null);
  const [sErr, setSerr] = useState(null);
  const [auctionFilter, setFilter] = useState<any>([]);
  const network = useSelector((state: RootState) => state.user.networkId);

  const getAuction = async () => {
    setLoading(true);
    try {
      let auctionFactory = await auctionFac(network);

      const all = await auctionFactory.methods.getAllAuctions().call();

      let y = all.map(async (item) => {
        let web3 = await web3Init();
        let auction = new web3.eth.Contract(AuctionAbi, item);
        const roi = await auction.methods.roi().call();
        const rate = await auction.methods.rate().call();
        const hardcap = await auction.methods.hardCap().call();
        const salesCompleted = await auction.methods.salesCompleted().call();
        const salesStarted = await auction.methods.salesStarted().call();
        const amountRaised = await auction.methods.amountRaised().call();
        const openTill = await auction.methods.openTill().call();
        const projectURL = await auction.methods.projectURL().call();
        const logoLink = await auction.methods.logoLink().call();

        const auctionAddress = item;

        let amountR = Math.round((amountRaised / hardcap) * 100);
        return {
          auctionAddress,
          roi,
          rate: web3.utils.fromWei(rate, "ether"),
          amountRaised: amountR,
          openPeriod: openTill,
          deposited: 2000,
          salesStarted,
          salesCompleted,
          projectURL,
          logoLink,
        };
      });

      let allAuction: AuctionInt[] = await Promise.all(y);
      let rev = allAuction.reverse();
      setAuctions(rev);

      let arr = allAuction
        .slice(0, limit)
        .filter((data) => data.salesStarted && !data.salesCompleted);
      setFilter(arr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (typeof web3 !== undefined) {
      getAuction();
    }
  }, [web3, network]);

  const handlePageChange = (data) => {
    const selected = data.selected;
    console.log(auctionFilter);
    let arr = [...auctions];
    let newa = arr.splice(selected * limit, limit);
    setFilter(newa);
  };

  const handleChage = (val) => {
    let arr = [...auctions];
    if (val === "Active") {
      let x = arr.filter((data) => data.salesStarted && !data.salesCompleted);
      setFilter(x);
      setLimit(1000);
    } else if (val === "All") {
      setFilter(arr);
    } else if (val === "Inactive") {
      let x = arr.filter((data) => !data.salesStarted);
      setFilter(x);
      setLimit(1000);
    } else if (val === "Closed") {
      let x = arr.filter((data) => data.salesCompleted === true);
      setFilter(x);
      setLimit(1000);
    }
  };

  const tokenSearch = async (token) => {
    setLoadingS(true);
    setSAuction(null);
    setSerr(null);
    let auctionFactory = await auctionFac(network);
    try {
      const auctionAddress = await auctionFactory.methods
        .getAuction(token)
        .call();
      console.log(auctionAddress);
      if (auctionAddress === "0x0000000000000000000000000000000000000000") {
        setSerr("Auction not found");
        setLoadingS(false);
      } else {
        let data = await getTokenDetails(auctionAddress, token);
        setSAuction(data);
        setLoadingS(false);
      }
    } catch (err) {
      setLoadingS(false);
      setSerr(err.message && "Invalid Address");
      console.log(err);
    }
  };

  const handleBlur = () => {
    setSAuction(null);
    setSerr(null);
  };

  return (
    <div>
      <div className='container'>
        <div className='home-header'>
          <header>
            <h1>Auctions</h1>
          </header>

          <div className='filter-con'>
            <div className='search'>
              <Search
                placeholder='Enter Address'
                enterButton='Search'
                onSearch={tokenSearch}
                onBlur={handleBlur}
                loading={loadingS}
                size='large'
              />
              {sAuction ? (
                <Link to={"/auction/" + sAuction.link} className='s-result'>
                  <Image
                    src={
                      sAuction.logo && sAuction.logo.length > 0
                        ? sAuction.logo
                        : `${process.env.PUBLIC_URL}/default.png`
                    }
                    alt='Deify Image'
                  />
                  <div className='details'>
                    <h4>{sAuction.name}</h4>
                    <p>
                      {sAuction.link.substr(0, 7) +
                        "..." +
                        sAuction.link.substr(-7)}
                    </p>
                  </div>
                  <div className='link'>View</div>
                </Link>
              ) : (
                <div>{sErr && <div className='s-result'>{sErr}</div>}</div>
              )}
            </div>
            <div>
              <Select
                onChange={handleChage}
                defaultValue='Active Auctions'
                size='large'
                placeholder='Filter By'
                style={{ width: 120 }}>
                <Option value='All'>All Auctions</Option>
                <Option value='Active'>Active Auctions</Option>
                <Option value='Inactive'>Inactive Auctions</Option>
                <Option value='Closed'>Completed Auctions</Option>
              </Select>
            </div>
          </div>
        </div>
        <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
          {loading && typeof web3 !== undefined ? (
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}
              size='middle'>
              <Spin size='large' />
            </Space>
          ) : auctionFilter.length > 0 ? (
            auctionFilter.map((auction, i) => (
              <AuctionCard
                id={auction.id}
                rate={auction.rate}
                roi={auction.roi}
                auctionAddress={auction.auctionAddress}
                amountRaised={auction.amountRaised}
                openPeriod={auction.openTill}
                key={i}
                closed={auction.salesCompleted}
                imageURL={auction.logoLink}
              />
            ))
          ) : (
            <EmptyAuction />
          )}
        </Row>
        {!loading && auctionFilter.length > 0 && (
          <ReactPaginate
            previeusLabel={"previous"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={auctions.length / limit}
            marginPagesDisplay={limit}
            pageRangeDisplay={limit}
            containerClassName={"pagination"}
            pageClassName={"pagespagination"}
            activeClassName={"active"}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default AuctionPage;
