import React, { FC, ReactElement } from "react";
import { Button, notification, Space } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import Moment from "react-moment";
// import { getFee } from "./../contracts";
import { toEth } from "./../utils/conv";

interface CaseProps {
  issue: string;
  upvoteCount: number;
  balanceOfToken: number;
  handleUpvote: () => void;
  showMore: () => void;
  symbol: string;
  index: number;
  caseType: number;
  getFee: any;
  address: string;
}

const CaseCard: FC<CaseProps> = ({
  issue,
  upvoteCount,
  balanceOfToken,
  handleUpvote,
  showMore,
  getFee,
  symbol,
  caseType,
}): ReactElement => {
  Moment.globalFormat = "D MMM YYYY";
  const web3 = window.web3;
  const close = () => {};
  const openNotification = async () => {
    let charge = await getFee();
    let { supportCaseFee, supportRefundCaseFees } = charge;

    const key = `open${Date.now()}`;
    const btn = (
      <div>
        <Space size='large'>
          <Button
            type='primary'
            size='small'
            onClick={() => notification.close(key)}>
            Cancel
          </Button>

          <Button
            type='primary'
            size='small'
            onClick={() => handleUpvote(caseType)}>
            Continue
          </Button>
        </Space>
      </div>
    );
    notification.open({
      message: "Up Vote Auction",
      description: `You will be charged  ${
        caseType === 1 ? supportCaseFee : supportRefundCaseFees
      } ether to up support this case`,
      btn,
      key,
      onClose: close,
      style: {
        backgroundColor: "#FFCCCC",
        fontWeight: "bold",
      },
    });
  };

  return (
    <div className='case-card case-bad'>
      <div>
        <div>
          {issue.length > 100 ? (
            <p>
              {issue.substr(0, 100)} ...{" "}
              <b style={{ cursor: "pointer" }} onClick={() => showMore(issue)}>
                show more
              </b>
            </p>
          ) : (
            <p>{issue}</p>
          )}
          <div>
            <b>
              {toEth(balanceOfToken, "ether")} {symbol}
            </b>
          </div>
        </div>
        <div className='count'>{Math.round(upvoteCount
          )} %</div>

        <div className='progress'>
          <div
            className='progress-inner'
            style={{
              width: upvoteCount + "%",
              backgroundColor: upvoteCount <= 50 ? "orange" : "red",
            }}></div>
        </div>
      </div>
      <div className='upvote'>
        <Button icon={<ArrowUpOutlined />} onClick={openNotification}></Button>
      </div>
    </div>
  );
};

export default CaseCard;
