import { useEffect,useState } from 'react'
import { ercContract as ercContractInit,getTokenAddress } from './../contracts'
import { toEth } from '../utils/conv'

const useToken=(auctionAddress)=>{
  const [token,setToken]= useState<any>({});   

  const getLocker=async ()=>{ 
        try {
          let address=await getTokenAddress(auctionAddress)
          const ercContract=await ercContractInit(address)
          let totalSupply = await ercContract.methods.totalSupply().call();
          const tokenName = await ercContract.methods.name().call();
          const symbol = await ercContract.methods.symbol().call();


          setToken({
            totalSupply:toEth(totalSupply),
            tokenName,
            symbol
          })
        } catch (error) {
          // console.log(error)
        }
  }
  
   useEffect(() => {
      getLocker()
   },[])

   return {
      token
   }
  }

  export default useToken
