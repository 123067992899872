import moment, { DurationInputArg1 } from "moment";
import web3 from "../connectors/web3";

export const toWei = (amount: string) =>
  typeof web3 !== undefined && web3.utils.toWei(amount, "ether");
export const toEth = (amount: string) =>
  typeof web3 !== undefined && web3.utils.fromWei(amount, "ether");

export const x = (cUpvote, contCount) =>
  Math.round((cUpvote * 100) / contCount);

export const canWithdraw = (thirdRelease, tokenBalance, exchangeLaunch) => {
  let third = thirdRelease + 24 * 60 * 60; // it should be 86,400s
  let current = Date.now();
  if (current > third && tokenBalance > 0 && exchangeLaunch) {
    return true;
  } else {
    return false;
  }
};

export const releaseText = (
  first: boolean,
  second: boolean,
  third: boolean,
  ethBal: string
) => {
  if (!first && parseFloat(ethBal) !== 0) { 
    return "Release 1st Liquidity";
  } else if (first && !second && !third && parseFloat(ethBal) !== 0) {
    return "Release 2nd Liquidity";
  } else if (first && second && !third && parseFloat(ethBal) !== 0) {
    return "Release 3rd Liquidity";
  } else if ((first || second || third) && parseFloat(ethBal) === 0) {
    return "All Liquidity Released";
  }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
};

export const enableRelease = (
  first,
  second,
  third,
  isFirstR,
  isSecondR,
  isThirdR,
  ethBal, 
  checkLaunched
): boolean                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 => {
  let current = (Date.now() / 1000 + 25).toFixed(0);
  return ((current > first && !isFirstR) || (current > second && !isSecondR) || (current > third && !isThirdR)) && ethBal > 0 && checkLaunched

};

export const addTime = (
  amount: DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor
) => {
  let dateVal = moment().add(amount, unit).calendar();
  let ts = moment(dateVal).format("X");
  return ts;
};

export const addDay = (
  amount: DurationInputArg1,
  unit: moment.unitOfTime.DurationConstructor
) => {
  let dateVal = moment().add(amount, unit);
  let ts = moment(dateVal).format("X");
  return ts;
};

// export const openDate=(openTill)=>{
//   let t=moment(openTill).add(10,'minute')
//   return new Date(t*1000)
// } 

export const checkRefund=(refundCase,cCount,thirdRelease)=>{
  return refundCase.length && ((refundCase.filter(({ upvote }) =>((upvote/cCount)*100) > 55 )).length || (Date.now() / 1000) > (thirdRelease + 300)) ? true : false 
};

// (Date.now() / 1000 + 5).toFixed(0)