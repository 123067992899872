import { createSlice } from '@reduxjs/toolkit'

const auctionSlice = createSlice({
  name: 'auctions',
  initialState: {
    auctions:[
      {
       id:1,
       tokenAddress:'0xf34563eef7e3b9d07d43e4283fbd05ea5b9135bbec3b8cb4d2769787fcd16a0b',
       roi:200,
       rate:0.6,
       amountRaised:60,
       openPeriod:new Date(),
       deposited:2000,
      },
      {
       id:2,
       tokenAddress:'0xf34563eef7e3b9d07d43e4283fbd05ea5b9135bbec3b8cb4d2769787fcd170c',
       roi:300,
       rate:0.5,
       amountRaised:20,
       openPeriod:new Date(),
       deposited:2000,
      },
      {
       id:3,
       tokenAddress:'0xf34563eef7e3b9d07d43e4283fbd05ea5b9135bbec3b8cb4d2769787fcd180d',
       roi:20,
       rate:0.3,
       amountRaised:100,
       openPeriod:new Date(),
       deposited:5000,
      },
      {
       id:4,
       tokenAddress:'0xf34563eef7e3b9d07d43e4283fbd05ea5b9135bbec3b8cb4d2769787fcd16a90h',
       roi:28,
       rate:0.3,
       amountRaised:20,
       openPeriod:new Date(),
       deposited:5000,
      }
    ],
    auction:{}
  },
  reducers: {
    newAuction: (state,{payload}) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
    
      state.auctions.push(payload);
      
    },
    getAuction:(state,{payload})=>{
      // return state.auction=state.auctions.find(data===payload)      
    }

  }
})


export const { newAuction,getAuction } = auctionSlice.actions


export default auctionSlice.reducer