import React, { FC,useState } from 'react';
import { Form, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface whiteInterface{
  loading:Boolean;
  onFinishWhite:()=>void
}

const WhiteList:FC<whiteInterface> = ({onFinishWhite,loading}) => {
  const [white,setWhite]=useState('') 
  const handleChange=(value)=>{
    setWhite(value)
   }  
  return (
      <div>
      <header>
        <h1>Whitelist Auction</h1>
      </header>
        <Form
          name='customized_form'
          layout='vertical'
          onFinish={onFinishWhite}
          initialValues={{
            whitelist: "",
          }}>
          <Form.Item
            tooltip='If filled, only specified addresses can participate in the sales.'
            name='whiteList'
            label='Whitelist address'>
            <TextArea
              style={{ width: "100%" }}
              placeholder='address1,address2,address3,...'
              autoSize={{
                minRows: 3,
              }}
              value={white || ''}
              onChange={handleChange}
            />
          </Form.Item>
          <div className='min-max-action'>
            <Form.Item>
              <Button
                loading={loading}
                type='primary'
                shape='round'
                htmlType='submit'
                disabled={!white}
                >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
    </div>

    )
}

export default WhiteList