import { useEffect, useState, useCallback } from "react";
import { auctionContract as auctionContractInit } from "./../contracts";
import { toEth } from "../utils/conv";

const useAuction = (auctionAddress,change) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [auction, setAuction] = useState<any>({});

  const getAuction = useCallback(async () => {
    setLoading(true);
    let auctionContract =await auctionContractInit(auctionAddress);
    try {
      let roi = await auctionContract.methods.roi().call();
      const amountRaised = await auctionContract.methods.amountRaised().call();
      let rate = await auctionContract.methods.rate().call();
      const initialLiquidity = await auctionContract.methods
        .initialLiquidity()
        .call();
      const salesCompleted = await auctionContract.methods
        .salesCompleted()
        .call();
      const exchangeLaunched = await auctionContract.methods
        .exchangeLaunched()
        .call();
      const amountToken = await auctionContract.methods.amountToken().call(); // token deposited
      const owner = await auctionContract.methods.owner().call();
      const getTokenBal = await auctionContract.methods.getTokenBal().call();
      const openTill = await auctionContract.methods.openTill().call();
      const minimumPurchaseEth = await auctionContract.methods
        .minimumPurchaseEth()
        .call();
      const maximumPurchaseEth = await auctionContract.methods
        .maximumPurchaseEth()
        .call();
      const availableTokenForSale = await auctionContract.methods
        .availableTokenForSale()
        .call();
      const hardcap = await auctionContract.methods.hardCap().call();
      const ethBal = await auctionContract.methods.getEthBal().call();
      const tokenAddress = await auctionContract.methods.token().call();

      let progress = Math.round((amountRaised / hardcap) * 100);

      setAuction({
        owner: owner,
        roi,
        rate: toEth(rate),
        initialLiquidity,
        amountRaised: toEth(amountRaised),
        tokenAddress,
        progress,
        getTokenBal,
        salesCompleted,
        ethBal,
        exchangeLaunched,
        amountToken: toEth(amountToken),
        availableTokenForSale: toEth(availableTokenForSale),
        hardcap: toEth(hardcap),
        change,
        openTill,
        minimumPurchaseEth,
        maximumPurchaseEth,
      });

      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  }, [auctionAddress, change]);

  useEffect(() => {
    getAuction();
  }, [getAuction]);

  return {
    auction,
    loading,
    error,
  };
};

export default useAuction;
