import React, { FC ,useState} from 'react';
import { Form, Button, Input } from "antd";

interface logoInterface{
  loading:Boolean;
  onFinishLogo:()=>void
}
const ProjectUrl:FC<logoInterface> = ({onFinishLogo,loading}) => {
  const [logo,setLogo]=useState('') 
  const handleChange=(value)=>{
    setLogo(value)
   }

    return (
        <div className="">
            <div>
            <header>
              <h1>Auction Logo & Project URL</h1>
            </header>
            <Form
              name='customized_form'
              layout='vertical'
              onFinish={onFinishLogo}
              initialValues={{
                logo: "",
                url: "",
              }}>
              <Form.Item
                tooltip='Link to the logo of the token for sale.'
                name='logo'
                label='Auction Logo URL'>
                <Input
                size='large'
                  style={{ width: "100%" }}
                  placeholder='logo url'
                  onChange={handleChange}
                  value={logo || ''}
                />
              </Form.Item>
              <Form.Item
                tooltip='Link to the website of the token for sale.'
                name='url'
                label='Project URL'>
                <Input
                size='large'
                  style={{ width: "100%" }}
                  placeholder='project URL'
                />
              </Form.Item>
              <div className='min-max-action'>
                <Form.Item>
                  <Button
                    loading={loading}
                    type='primary'
                    shape='round'
                    htmlType='submit'
                    disabled={!logo}>
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>

    )
}

export default ProjectUrl