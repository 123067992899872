import React, { FC, ReactElement } from "react";

const ContributorsPage: FC = (): ReactElement => {
  return (
    <div className=''>
      <h1>Contributors Page</h1>
    </div>
  );
};

export default ContributorsPage;
