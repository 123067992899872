import { useEffect, useState, useCallback } from "react";
import { auctionContract as auctionContractInit } from "../contracts";

const useRelease = (auctionAddress, change) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [release, setRelease] = useState<any>({});

  const getAuction = useCallback(async () => {
    setLoading(true);
    try {
      let auctionContract =await auctionContractInit(auctionAddress);
      const firstRelease = await auctionContract.methods.firstRelease().call();
      const secondRelease = await auctionContract.methods
        .secondRelease()
        .call();

      const thirdRelease = await auctionContract.methods.thirdRelease().call();
      const isFirstR = await auctionContract.methods.isFirstReleased().call();
      const isSecondR = await auctionContract.methods.isSecondReleased().call();
      const isThirdR = await auctionContract.methods.isThirdReleased().call();
      const getTokenBal = await auctionContract.methods.getTokenBal().call();

      setRelease({
        isFirstR,
        isSecondR,
        isThirdR,
        firstRelease,
        secondRelease,
        thirdRelease,
        getTokenBal,
        change,
      });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [auctionAddress, change]);

  useEffect(() => {
    getAuction();
  }, [getAuction]);

  return {
    release,
    loading,
    error,
  };
};

export default useRelease;
