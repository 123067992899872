import React, { FC, ReactElement } from "react";
import { Button, Image, Progress, Tag } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Col } from "antd";
import Moment from "react-moment";
import classNames from "classnames";
import { NETWORK_ETH } from "./../address";
import { RootState } from "../features/rootReducers";
import { useSelector } from "react-redux";
interface AuctionProps {
  id: number;
  rate: number;
  roi: number;
  auctionAddress: string;
  amountRaised: number;
  openPeriod: Date;
  closed?: boolean;
  imageURL?: string;
  projectURL?: string;
}

const AuctionCard: FC<AuctionProps> = ({
  rate,
  auctionAddress,
  amountRaised,
  openPeriod,
  id,
  roi,
  closed,
  imageURL,
  projectURL,
}): ReactElement => {
  Moment.globalFormat = "D MMM YYYY";
  const network = useSelector((state: RootState) => state.user.networkId);
  return (
    <Col xs={24} sm={12} md={12} lg={8}>
      <div className={classNames("auction-card", { closed: closed })}>
        <Link to={"/auction/" + auctionAddress}>
          <div className='img'>
            <div className='address'>
              <Button shape='round' ghost>
                {auctionAddress.substr(0, 5) +
                  "..." +
                  auctionAddress.substr(-5)}
              </Button>
            </div>
            <div className='p-web'>
              {projectURL && (
                <div className='project_url'>
                  <a
                    rel='noopener noreferrer'
                    target='_blank'
                    href={`${projectURL}` || "#"}>
                    <i className='fas fa-2x fa-external-link-square-alt'></i>
                  </a>
                </div>
              )}
            </div>
            {+network === NETWORK_ETH ? (
              <Image
                style={{ backgroundColor: "#dedede" }}
                width={"100%"}
                height={"170px"}
                src={
                  imageURL && imageURL.length > 0
                    ? imageURL
                    : `${process.env.PUBLIC_URL}/default.png`
                }
              />
            ) : (
              <Image
                style={{ backgroundColor: "#dedede" }}
                width={"100%"}
                height={"170px"}
                src={
                  imageURL && imageURL.length > 0
                    ? imageURL
                    : `${process.env.PUBLIC_URL}/icon_dex.svg`
                }
              />
            )}
          </div>
        </Link>

        <div className='details'>
          <div>
            <div className='left'>
              <Tag color='success'>
                Selling Rate : <b> {rate} </b>
              </Tag>
            </div>
            <Tag color='success'>
              ROI : <b> {roi} </b>
            </Tag>
            {/* <div className='bspan'>
                <span>
                  <ClockCircleOutlined /> <Moment>{openPeriod}</Moment>
                </span>
              </div> */}
          </div>
          <div>
            <Progress
              type='circle'
              percent={amountRaised}
              width={50}></Progress>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AuctionCard;
