import React, { FC, ReactElement } from "react";

const PageNotFound: FC<CaseProps> = (): ReactElement => {
  return (
    <div className='page-404'>
      <h1>Page not found</h1>
    </div>
  );
};

export default PageNotFound;
