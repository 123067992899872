import Web3 from "web3";
import {Arkane} from "@arkane-network/web3-arkane-provider";

let web3;
if (typeof window !== "undefined" && typeof window.web3 !== "undefined") {
  // we are in the browser and metamask is running
  web3 = new Web3(window.web3.currentProvider);
} else if (window.web3) {
  web3 = new Web3(window.web3.currentProvider);
  window.ethereum.enable();
} else {
  // let providerUrlBsc = 'https://apis.ankr.com/722770fc26fe4d28bb1c394b38a79f5f/2fd13f3bcd9904f06659cba8f76b8553/binance/full/test'
  // let providerUrlEth =  "https://rinkeby.infura.io/v3/ae69348d48e148e79799e35958268f01"
  // const provider = new Web3.providers.HttpProvider(
  //   // providerUrlEth
  //   providerUrlBsc
  // );
  // const provider = new Web3.providers.HttpProvider(
  //   "https://apis.ankr.com/aac7d4d550eb43bebba60d19dfad3a64/4144a58081064744f91bf740665b3997/binance/full/test",
  // );

   const provider = new Web3.providers.HttpProvider("https://rinkeby.infura.io/v3/ae69348d48e148e79799e35958268f01");
  
   web3 = new Web3(provider);                              

  // Arkane.createArkaneProviderEngine({clientId:'Arketype'}).then(provider=>{
  //   web3 = new Web3(provider);
  // })
}

export default web3;



// import Web3 from "web3";

// let web3;

// if (window.ethereum) {
//     window.web3 = new Web3(window.ethereum)
//    window.ethereum.enable()
//   }
//   else if (window.web3) {
//     window.web3 = new Web3(window.web3.currentProvider)
//   }
//   else {
//     const provider = new Web3.providers.HttpProvider("https://rinkeby.infura.io/v3/ae69348d48e148e79799e35958268f01");
//     window.web3 = new Web3(provider);
// }

// export default web3;
