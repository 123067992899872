import React, { FC } from 'react';
import { Form, Button,InputNumber } from "antd";

interface DepositInterface{
  appLoading:Boolean;
  loading:Boolean;
  approve:Boolean;
  onFinishDeposit:()=>void;
  handleApprove:()=>void;
  checkAllowance:()=>void;

}
const Deposit:FC<DepositInterface> = ({onFinishDeposit,handleApprove,loading,appLoading,checkAllowance,approve}) => {

    return (
      <div>
            <header>
              <h1>Deposit</h1>
            </header>
            <Form
              name='customized_form_controls'
              layout='vertical'
              onFinish={onFinishDeposit}
              initialValues={{
                initialTokenLiquidity: "",
                amountForSale: "",
                reserve: "",
                deflationFactor: "",
              }}>
              <Form.Item
                tooltip='Amount of token to be sent to a Dex for initial liquidity.'
                name='initialTokenLiquidity'
                rules={[{ required: true, message: "Selling rate is requred" }]}
                label='Initial token liquidity'>
                <InputNumber
                size='large'
                  style={{ width: "100%" }}
                  placeholder='Initial token liquidity'
                  step='any'
                />
              </Form.Item>
              <Form.Item
                tooltip='Total amount of token for sale during the auction.'
                name='amountForSale'
                rules={[{ required: true, message: "Field Required" }]}
                label='Amount for sale'>
                <InputNumber
                size='large'
                  style={{ width: "100%" }}
                  placeholder='Amount for sale'
                  step='any'
                />
              </Form.Item>

              <Form.Item
                tooltip='Amount of token for additional liquidity if applied.'
                name='reserve'
                label='Reserve'
                rules={[{ required: true, message: "Field Required" }]}>
                <InputNumber
                size='large'
                  style={{ width: "100%" }}
                  placeholder='Reserve'
                  step='any'
                />
              </Form.Item>
              <Form.Item
                tooltip='Specify the deflationary percentage of the token for sale is a deflationary token.'
                name='deflationFactor'
                label='Deflation factor'
                rules={[{ required: true, message: "Field Required" }]}>
                <InputNumber
                size='large'
                  style={{ width: "100%" }}
                  placeholder='Deflation factor'
                  step='any'
                />
              </Form.Item>

              <div className='min-max-action'>
                <Button
                  disabled={!checkAllowance() || !approve}
                  onClick={handleApprove}
                  loading={appLoading}
                  type='primary'
                  shape='round'
                  ghost
                  disabled={!approve}>
                  Approve
                </Button>
                <Form.Item>
                  <Button
                    disabled={approve}
                    loading={loading}
                    type='primary'
                    shape='round'
                    htmlType='submit'>
                    Deposit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
    )
}

export default Deposit