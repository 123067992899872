import React from 'react';
import { Button,notification,Space} from 'antd';
import {ETHERSCAN_URL,BINANCE_URL,NETWORK_ETH} from "./../address";
import store from "./../store";



export interface Notify {
    title: string,
    msg: string,
    url?: string,
    type:string,
}


const getUrl=()=>{
  let data=store.getState()
  let network=data.user.networkId

  let etherscanUrl =parseInt(network) === NETWORK_ETH ? ETHERSCAN_URL : BINANCE_URL;

  return etherscanUrl
}


   export const openNotification = (handleUpvote,close) => {
        const key = `open${Date.now()}`;
        const btn = (
          <div>
              <Space size="large">
              <Button type="primary" size="small" onClick={() => notification.close(key)}>
                Cancel
            </Button>

            <Button type="primary" size="small" onClick={handleUpvote}>
                Continue
            </Button>
              </Space>
          </div>
        );
        notification.open({
          message: 'Up Vote Auction',
          description:
            'You will be charge ...eth to up vote this auction',
          btn,
          key,
          onClose: close,
        });
      };
  




export const openNotificationWithIcon = (type, title, msg, hash) => {

  let etherscanUrl = getUrl()

		const key = `open${Date.now()}`;
		const btn =hash && (
			<div>
				<Button href={etherscanUrl + hash} target="_blank" type="primary" size="small" onClick={() => notification.close(key)}>
					View txn
			  </Button>
			</div>
		)

		type === "success"
			? notification["success"]({
				duration: 6,
				message: title,
				description: msg,
                btn
        
			})
			: notification["error"]({
				duration: 6,
				message: title,
				description: msg,
        btn,
        style: {
          backgroundColor: "#FFCCCC",
          fontWeight: "bold"
        },
			});
	};

  
	export const openiNotification =async (submitIssue,charge,type) => {
    
	  
		let {createCaseFee,createReFundCaseFees}=await charge;
		const key = `open${Date.now()}`;
		const btn = (
			<div>
				<Space size="large">
					<Button
						type="primary"
						size="small"
						onClick={() => notification.close(key)}
					>
						Cancel
					</Button>

					<Button type="primary" size="small" onClick={submitIssue}>
						Continue
					</Button>
				</Space>
			</div>
		);
		notification.open({
			message: "Case Action",
			description: `You will be charged ${type===0 ? createCaseFee : createReFundCaseFees} ether to create case this auction.`,
			btn,
			key,
			style: {
				backgroundColor: "#FFCCCC",
				fontWeight: "bold"
			},
		});
	};



export const openNotificationWithIconB = (type, title, msg, hash) => {
   
   let etherscanUrl = getUrl()

    const key = `open${Date.now()}`;

    const btn = (
      <div>
        <Button
          href={etherscanUrl + hash}
          target='_blank'
          type='primary'
          size='small'
          onClick={() => notification.close(key)}>
          View txn
        </Button>
      </div>
    );

    type === "success"
      ? notification["success"]({
          duration: 6,
          message: title,
          description: msg,
          btn,
        })
      : notification["error"]({
          duration: 6,
          message: title,
          description: msg,
          btn,
        });
  };
