const network = (network) => {
  switch (network) {
    case "1":
      return "Ethereum (Mainnet)";
    case "3":
      return "Ropsten Testnet";
    case "4":
      return "Rinkeby Testnet";
    case "5":
      return "Goerli Testnet";
    case "42":
      return "Kovan Testnet";
    case "56" || "0x38":
      return "Binance (Mainnet)";
    case "0x61" || "97":
      return "Binance Testnet";
    default:
      return "UNKNOWN NETWORK";
  }
};

export default network;
