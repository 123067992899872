import {configureStore,getDefaultMiddleware} from '@reduxjs/toolkit'
import {rootReducers} from './features/rootReducers'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
 })

const store=configureStore({
    reducer:rootReducers,
    middleware:customizedMiddleware
})


export default store;