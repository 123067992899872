import React, { FC, ReactElement, useState, useEffect } from "react";
import { Button, Dropdown, Menu } from "antd";
import {
  PlusCircleFilled,
  ArrowRightOutlined,
  MenuFoldOutlined,
  CloseOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import useAuction from "./../hooks/auction";
import { RouteComponentProps } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./../features/rootReducers";
import net from "./../utils/network";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { web3 } from "./../connectors/web3modal";

interface RouterProps {
  id: string;
}
interface MatchProps extends RouteComponentProps<RouterProps> {}

const NavBar: FC<MatchProps> = () => {
  const location = useLocation();
  let url = window.location.pathname;
  let auctionAddress = url.split("/")[2];
  const dispatch = useDispatch();

  const [change] = useState(false);
  const account = useSelector((state: RootState) => state.user.address);
  const network = useSelector((state: RootState) => state.user.networkId);
  const {
    auction: { salesStarted, tokenAddress, owner },
  } = useAuction(auctionAddress, change);

  const [show, setShow] = useState(false);

  const handleConnect = async () => {
    web3();
  };

  const menu = (
    <Menu>
      <Menu.Item>Ethereum (Mainnet)</Menu.Item>
      <Menu.Item>Binance (Mainnet)</Menu.Item>
    </Menu>
  );

  useEffect(() => {
    return () => {
      setShow(false);
    };
  }, [location]);

  return url !== "/" ? (
    <div className='nav'>
      <div className='nav-inner'>
        <div className='logo'>
          <h1>
            <Link to='/'>
              <img
                width={"50px"}
                height={"50px"}
                src={`${process.env.PUBLIC_URL}/logo.png`}
              />
            </Link>
          </h1>
        </div>
        <div className='right-menu'>
          <CSSTransition in={show} timeout={300} classNames='menu-t'>
            <div className={classNames("mobile-menu", { show: show })}>
              <div className='cancel' onClick={() => setShow(false)}>
                <CloseOutlined />
              </div>
              <div className='inner'>
                {account && account.length > 0 ? (
                  <>
                    <li>
                      <Dropdown overlay={menu}>
                        <Button type='primary' shape='round'>
                          {network && net(network)}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </li>
                    <li>
                      <Link to='/create-auction'>
                        <Button
                          type='primary'
                          shape='round'
                          ghost
                          icon={<PlusCircleFilled />}>
                          Create Auction
                        </Button>
                      </Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button
                      onClick={handleConnect}
                      type='primary'
                      shape='round'
                      ghost
                      icon={<PlusCircleFilled />}>
                      Connect
                    </Button>
                  </li>
                )}
              </div>
            </div>
          </CSSTransition>
          <ul>
            <li className=''>
              <Link to='/auctions'>Auctions</Link>
            </li>
            {location.pathname.startsWith("/auction") &&
              !salesStarted &&
              account === (owner && owner.toLowerCase()) &&
              account.length > 0 && (
                <li className=''>
                  <Link to={"/setup/" + tokenAddress}>
                    <Button
                      type='primary'
                      shape='round'
                      icon={<ArrowRightOutlined />}>
                      Continue to deposit
                    </Button>
                  </Link>
                </li>
              )}
            {account && account.length > 0 ? (
              <>
                <li className='home'>
                  <Dropdown overlay={menu}>
                    <Button type='primary' shape='round'>
                      {network && net(network)}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </li>
                <li className='home'>
                  <Link to='/create-auction'>
                    <Button
                      type='primary'
                      shape='round'
                      ghost
                      icon={<PlusCircleFilled />}>
                      Create Auction
                    </Button>
                  </Link>
                </li>
              </>
            ) : (
              <li>
                <Button
                  onClick={handleConnect}
                  type='primary'
                  shape='round'
                  ghost
                  icon={<PlusCircleFilled />}>
                  Connect
                </Button>
              </li>
            )}
            <li className='bar' onClick={() => setShow(true)}>
              <MenuFoldOutlined />
            </li>
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div className='nav-home'>
      <Link to='/'>
        <img
          width={"50px"}
          height={"50px"}
          src={`${process.env.PUBLIC_URL}/logo.png`}
        />
      </Link>
    </div>
  );
};

export default NavBar;
