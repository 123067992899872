import React, { FC, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useSelector } from "react-redux";
import {
  auctionContract as auctionContractInit,
  getAuctionAddress,
  ercContract as ercContractInit,
} from "./../contracts";
import { toWei } from "./../utils/conv";

import { Button, Space } from "antd";
import { RootState } from "../features/rootReducers";
import { useHistory } from "react-router-dom";
import { MinMax, Deposit, Whitelist, ProjectUrl } from "./../components";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import {
  openNotificationWithIcon,
  openNotificationWithIconB
} from "./../components/Notify";


interface RouterProps {
  token: string;
}

interface MatchProps extends RouteComponentProps<RouterProps> {}

const AuctionSetupPage: FC<MatchProps> = ({
  match: {
    params: { token },
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [appLoading, setAppLoading] = useState(false);
  const [approve, setApproved] = useState(true);
  const address = useSelector((state: RootState) => state.user.address);
  const network = useSelector((state: RootState) => state.user.networkId);

  const [paginate, setPaginate] = useState({
    active: 1,
    pages: 4,
  });

  const history = useHistory();

  const onFinishMinMax = async (values) => {
    setLoading(true);
    console.log(values);
    try {
      let auctionAddress = await getAuctionAddress(token, network);
      let auctionContract = await auctionContractInit(auctionAddress);

      await auctionContract.methods
        .setMinMax(toWei(values.min.toString()), toWei(values.max.toString()))
        .send({ from: address.toString() });
      openNotificationWithIcon(
        "success",
        "Success",
        "Minimum and maximum values has ben set",
      );
      handleNext();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Min&Max Error",
        "Oops, something went wrong please try again later",
      );
    }
  };

  const onFinishWhite = async (values) => {
    setLoading(true);
    console.log("Value: ", values);
    let addresses = values.whiteList.split(",");
    let auctionAddress = await getAuctionAddress(token, network);
    let auctionContract = await auctionContractInit(auctionAddress);
    console.log("Addresses: ", addresses);
    try {
      await auctionContract.methods
        .whitelistAddresses(addresses)
        .send({ from: address });

      openNotificationWithIcon("success", "Success", "White list has been set");
      handleNext();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "WhiteList Error",
        "Oops, something went wrong please try again later",
      );
    }
  };

  const onFinishLogo = async (values) => {
    setLoading(true);
    let auctionAddress = await getAuctionAddress(token, network);
    let auctionContract = await auctionContractInit(auctionAddress);
    try {
      await auctionContract.methods
        .addProjectInfo(values.logo, values.url)
        .send({ from: address });

      openNotificationWithIcon("success", "Success", "logo has been set");
      handleNext();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "logo Error",
        "Oops, something went wrong please try again later",
      );
    }
  };

  const onFinishDeposit = async (values) => {
    setLoading(true);

    let auctionAddress = await getAuctionAddress(token, network);
    let auctionContract = await auctionContractInit(auctionAddress);

    await auctionContract.methods
      .deposit(
        toWei(values.initialTokenLiquidity.toString()),
        toWei(values.amountForSale.toString()),
        toWei(values.reserve.toString()),
        values.deflationFactor,
      )
      .send({ from: address.toString() })
      .once("transactionHash", (hash) => {
        history.push("/auctions");
        openNotificationWithIconB(
          "success",
          "Deposited",
          "Deposit Pending...it can take few minute but we don't want to keep you waiting click on the button below to view the status",
          hash,
        );
        setLoading(false);
      })
      .on("error", (err) => {
        console.log(err);
      })
      .on("receipt", () => {
        setLoading(false);
      })
      .then((data) => {
        setLoading(false);
        openNotificationWithIcon("success", "Deposited", "Deposit successful");
      })
      .catch((error) => {
        setLoading(false);
        openNotificationWithIcon(
          "error",
          "Deposited Failed",
          "Opps, deposit failed please check your input and try again",
        );
      });

    // const trax=await auctionContract.getPastEvents('Transfer',{fromBlock:0,toBlock:'latest'})

    // auctionContract.events.Deposited(address,auctionAddress,toWei(values.initialTokenLiquidity.toString()))
    // .on('data',(data)=>{
    //        setLoading(false);
    // 	   openNotificationWithIcon("success", "Deposited", "Deposit successful");
    // 	   history.push('/');
    // })
  };

  

  const checkAllowance = async (): Promise<boolean> => {
    let auctionAddress = await getAuctionAddress(token, network);
    let ercContract = await ercContractInit(token);
    const totalSupply: string = await ercContract.methods.totalSupply().call();

    const allowance: string = await ercContract.methods
      .allowance(address.toString(), auctionAddress)
      .call();

    allowance === totalSupply ? setApproved(false) : setApproved(true);
    return allowance === totalSupply;
  };

  const handleApprove = async () => {
    setAppLoading(true);
    try {
      let auctionAddress = await getAuctionAddress(token, network);
      let ercContract = await ercContractInit(token);
      const totalSupply = await ercContract.methods.totalSupply().call();
      await ercContract.methods
        .approve(auctionAddress, totalSupply)
        .send({ from: address });
      await ercContract.methods.allowance(address, auctionAddress).call();
      openNotificationWithIcon(
        "success",
        "Approved",
        "You have successfully approve your account, you can now proceed to deposit",
      );

      setApproved(false);
      setAppLoading(false);
    } catch (error) {
      console.log(error);
      setAppLoading(false);
    }
  };

  let { pages, active } = paginate;
  const handleNext = () => {
    let next = active + 1;
    setPaginate((prev) => ({
      ...prev,
      active: next,
    }));
  };

  const handlePrev = () => {
    let next = active - 1;
    setPaginate((prev) => ({
      ...prev,
      active: next,
    }));
  };

  return (
    <div className='auction-setup'>
      <div className='inner'>
        {active === 1 ? (
          <MinMax loading={loading} onFinishMinMax={onFinishMinMax} />
        ) : active === 2 ? (
          <ProjectUrl loading={loading} onFinishLogo={onFinishLogo} />
        ) : active === 3 ? (
          <Whitelist loading={loading} onFinishWhite={onFinishWhite} />
        ) : (
          <Deposit
            appLoading={appLoading}
            handleApprove={handleApprove}
            loading={loading}
            onFinishDeposit={onFinishDeposit}
            checkAllowance={checkAllowance}
            approve={approve}
          />
        )}
      </div>

      <div className='paginate'>
        <Button
          disabled={active == 1 || loading || appLoading}
          type='primary'
          shape='round'
          onClick={handlePrev}>
          <LeftOutlined /> Prev
        </Button>
        <Space size='middle'></Space>
        <Button
          disabled={active == 4 || loading || appLoading}
          shape='round'
          type='primary'
          onClick={handleNext}>
          Next <RightOutlined />{" "}
        </Button>
      </div>
    </div>
  );
};
export default AuctionSetupPage;
