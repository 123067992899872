import React, { FC, useState } from "react";
import { Form, InputNumber, Row, Col, Button } from "antd";

interface minMaxInterface {
  loading: Boolean;
  onFinishMinMax: () => Promise<void>;
}

const MinMax: FC<minMaxInterface> = ({ onFinishMinMax, loading }) => {
  const [min, setMin] = useState("");
  const handleChange = (value) => {
    setMin(value);
  };
  return (
    <div className=''>
      <div className='min-max'>
        <header>
          <h1>Minimum and Maximum </h1>
        </header>
        <Form
          name='customized_form'
          layout='vertical'
          onFinish={onFinishMinMax}
          initialValues={{
            min: "",
            max: "",
          }}>
          <Row>
            <Col span={12}>
              <Form.Item
                tooltip='Minimum amount of ETH an address can contribute to an auction'
                name='min'
                label='Min'>
                <InputNumber
                  value={min || ""}
                  size='large'
                  style={{ width: "90%" }}
                  step='any'
                  placeholder='Minimum'
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                tooltip='Maximum amount of ETH an address can contribute to an auction.'
                name='max'
                label='Max'>
                <InputNumber
                  size='large'
                  style={{ width: "100%" }}
                  placeholder='Maximum'
                  step='any'
                />
              </Form.Item>
            </Col>
          </Row>

          <div className='min-max-action'>
            <Form.Item>
              {
                <Button
                  loading={loading}
                  type='primary'
                  shape='round'
                  htmlType='submit'
                  disabled={!min}>
                  Submit
                </Button>
              }
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MinMax;
