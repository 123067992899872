import React, { FC, useState } from "react";
import { RouteComponentProps } from "react-router";

import { useSelector } from "react-redux";
import { RootState } from "./../features/rootReducers";
import { Link } from "react-router-dom";

import {
  Button,
  Col,
  Row,
  Divider,
  Space,
  Spin,
} from "antd";
import {} from "@ant-design/icons";

import { ClockCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import useLocker from "./../hooks/locker";
import useAuction from "./../hooks/auction";
import useRelease from "./../hooks/release";
import useToken from "./../hooks/token";
import useCases from "./../hooks/cases";
import { openNotificationWithIcon } from "./../components/Notify";

import {
  auctionContract as auctionContractInit,
  lockerContract as lockerContractInit,
} from "./../contracts";

import { toEth } from "./../utils/conv";
import moment from "moment";

import Modal from "antd/lib/modal/Modal";

interface RouterProps {
  id: string;
}

interface MatchProps extends RouteComponentProps<RouterProps> {}

const AuctionLockerPage: FC<MatchProps> = ({
  match: {
    params: { id: auctionAddress },
  },
}) => {
  const account = useSelector((state: RootState) => state.user.address);
  const network = useSelector((state: RootState) => state.user.networkId);
  const {
    loading,
    amount,
    locker: {
      auctionLockPeriod,
      LPTokenburned,
    },
  } = useLocker(network, auctionAddress);
  const { token } = useToken(auctionAddress);
  const { auction } = useAuction(auctionAddress, false);
  const { cases } = useCases(auctionAddress, false);
  const [loadingL, setLoadingL] = useState(false);
  const [loadingW, setLoadingW] = useState(false);
  const [loadingRs, setLoadingRs] = useState(false);
  const [visible, setVisible] = useState(false);
  const [amountLP, setAmountLP] = useState("");
  const { release } = useRelease(auctionAddress, false);

  const [hash, setHash] = useState<string>("");


  Moment.globalFormat = "D MMM YYYY HH:mm";

  const checkBurnable = () => cases.filter(({ passed }) => passed).length >= 2;
  const burnLiquidityFromAuction = async () => {
    let auctionContract = auctionContractInit(auctionAddress);

    setLoadingL(true);
    try {
      await auctionContract.methods
        .burnInitialLiquidity()
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err, result) => {
          openNotificationWithIcon(
            "success",
            "Error",
            "Opps something went wrong",
            result.transactionHash,
          );
        });
      openNotificationWithIcon(
        "success",
        "Success",
        "Liquidity Token Burned!!",
        hash,
      );
      setLoadingL(false);
    } catch (err) {
      setLoadingL(false);
    }
  };
  const getLockPeriod = () =>
    auctionLockPeriod === undefined
      ? "???"
      : moment(
          new Date(+auctionLockPeriod * 1000 - Date.now()),
          "YYYYMMDD",
        ).fromNow();
  const getLpAmount = () =>
    amount === undefined ? 0 : parseFloat(toEth(amount)).toFixed(6);

  const burnLockerLp = async () => {
    let auctionContract =await auctionContractInit(auctionAddress);
    let lockerContract =await lockerContractInit(network);
    const token = await auctionContract.methods.token().call(); // fetching the Token from the auction
    console.log("Token: ", token);
    const totalLP = await lockerContract.methods
      .getLPAmount(auctionAddress)
      .call(); //using the fetched address to get the amount. the amount should be the user input
    setLoadingL(true);
    try {
      await lockerContract.methods
        .burnDexLPToken(token, totalLP)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
          console.log(hash);
        })
        .on("error", (err) => {
          openNotificationWithIcon(
            "error",
            "Error",
            "Opps something went wrong",
            hash,
          );
        });
      setLoadingL(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Liquidity Token Burned!!",
        hash,
      );
    } catch (error) {
      setHash(hash);
      setLoadingL(false);

      openNotificationWithIcon(
        "error",
        "Opps something went wrong",
        error.message,
        hash,
      );
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const openModal = () => {
    setVisible(true);
  };
  const handleChange = (e) => {
    setAmountLP(e.target.value);
  };

  const withdrawLockerLp = async () => {
    let auctionContract = auctionContractInit(auctionAddress);
    let lockerContract = lockerContractInit(network);
    setLoadingW(true);
    const token = await auctionContract.methods.token().call(); // fetching the Token from the auction
    // console.log("Token: ", token);
    // const totalLP = await lockerContract.methods
    //   .getLPAmount(auctionAddress)
    //   .call(); //using the fetched address to get the amount. the amount should be the user input
    try {
      await lockerContract.methods
        .withdrawLpToken(token, amountLP)
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        });
      setLoadingL(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Liquidity Token Burned!!",
        hash,
      );
      setLoadingW(false);
    } catch (err) {
      setLoadingW(false);
      openNotificationWithIcon(
        "error",
        "Opps something went wrong",
        err.message,
        hash,
      );
    }
  };
  const withdrawReserve = async () => {
    let auctionContract =await auctionContractInit(auctionAddress);
    setLoadingRs(true);
    try {
      await auctionContract.methods
        .withdrawToken()
        .send({ from: account })
        .on("transactionHash", (hash) => {
          setHash(hash);
        })
        .on("error", (err) => {
          setHash(hash);
        });
      setLoadingRs(false);
      openNotificationWithIcon(
        "success",
        "Success",
        "Reserve Tokens withdrawal was successful!",
        hash,
      );
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Opps something went wrong",
        error.message,
        hash,
      );
      setLoadingRs(false);
    }
  };

  return (
    <div className="locker-page" style={{ margin: "10px" }}>
      <Row>
        <Col style={{ margin: "auto" }} xs={24} sm={24} md={8} lg={8}>
          <div className='locker-con'>
            <div className='back' style={{ fontSize: "24px" }}>
              <Link to={`/auction/${auctionAddress}`}>
                <ArrowLeftOutlined />
              </Link>
            </div>
            <header>
              <h1>Locker</h1>
            </header>
            {loading ? (
              <Space
                size='middle'
                style={{ display: "flex", justifyContent: "center" }}>
                <Spin size='default' />
              </Space>
            ) : (
              <div className='locker-body'>
                <div className='row'>
                  <div className='title'>Release date</div>
                  <div>
                    {LPTokenburned
                      ? "Liquidity is Locked forever!"
                      : getLockPeriod()}{" "}
                  </div>
                </div>

                {/* <div className="row">
								  <div className="title">LPBurned</div>
								  <div>{LPBurned ? "Token is bound" : "Token is not burned" }</div>
							  </div> */}

                <div className='row'>
                  <div className='title'>LP Amount</div>
                  <div>{getLpAmount()}</div>
                </div>

                <div className='action'>
                  <Divider style={{ color: "#0707a2" }}>
                    Auction creator actions
                  </Divider>
                  {/* disabled={
                      Date.now() / 1000 < auctionLockPeriod ||
                      getLpAmount() == 0
                    } */}
                  <div className='action-row'>
                    <Button
                      style={{
                        color: "#fff",
                        backgroundColor: "#36e079",
                        margin: "10px",
                      }}
                      onClick={openModal}
                      shape='round'
                      type='primary'>
                      Withdraw LPT
                    </Button>
                    {/* {(Date.now() / 1000).toFixed(0) >
                      release.thirdRelease + 3600 &&
                      release.getTokenBal !== 0 && ( */}
                    <Button
                      onClick={withdrawReserve}
                      type='primary'
                      shape='round'
                      disabled={
                        parseFloat(release.getTokenBal) === 0 ||
                        (Date.now() / 1000).toFixed(0) <
                          release.thirdRelease + 3600
                      }
                      loading={loadingRs}>
                      {parseFloat(release.getTokenBal) === 0
                        ? "Reserve was emptied"
                        : "Withdraw Reserve"}
                    </Button>
                    {/* )} */}
                    <Button
                      disabled={+getLpAmount() === 0}
                      onClick={burnLockerLp}
                      shape='round'
                      style={{ marginBottom: "10px" }}
                      danger
                      type='primary'>
                      Burn LPT
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <Modal
              title={`Withdraw Liquidity Provider Token`}
              visible={visible}
              onOk={withdrawLockerLp}
              confirmLoading={loadingW}
              onCancel={handleCancel}
              okText='Withdraw'>
              <form>
                <div className='c-input'>
                  <input
                    className='input'
                    type='number'
                    placeholder='0.0'
                    onChange={handleChange}
                    value={amountLP}
                    step='any'
                  />
                </div>
              </form>
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuctionLockerPage;
