import React, { FC, ReactElement } from 'react';
import Empty from "./../svgIcon/empty";
import { Link } from "react-router-dom";
import { Button } from "antd";


const EmptyAuction: FC = (): ReactElement => {

    return (
        <div className='auction-empty'>
          <Empty />
          <h2>Empty Auction</h2>
          <p>Be the first to create an auction</p>
          <Link to='/create-auction'>
                  <Button size='large' shape="round" type='primary'>
                    Create Auction
                  </Button>
                </Link>
       </div>
    )
}

export default EmptyAuction