import logo from "./logo.svg";
import "./App.less";
import "./App.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./features/rootReducers";
import { setUser, setNetwork } from "./features/user/UserSlice";
import { setFactory } from "./features/contracts/ContractSlice";
import { NavBar, Connect, Footer } from "./components";
import {Arkane} from "@arkane-network/web3-arkane-provider";

import {
  HomePage,
  CreateAuctionPage,
  ContributorsPage,
  AuctionPage,
  AuctionDetailsPage,
  AuctionSetupPage,
  AuctionLockerPage,
  AuctionCasePage,
  NotFound,
} from "./pages";
import { Route, Switch } from "react-router-dom";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

function App() {
  const account = useSelector((state: RootState) => state.user.address);
  // const network = useSelector((state: RootState) => state.user.networkId);
  const dispatch = useDispatch();


  useEffect(() => {
    loadBlockchainData();
  }, []);
  
  const loadBlockchainData = async () => {
    const accounts= await ethereum.request({ method: 'eth_accounts' })
    const networkId=await ethereum.networkVersion

    if (accounts.length > 0) {
      dispatch(setUser(accounts[0]));
      dispatch(setNetwork(networkId));
    }
  };

  return (
    <div className='App'>
      <div className='wrapper'>
        <NavBar />

        <div className='content'>
          <Switch>
            <Route exact path='/' component={HomePage} />
            {account && account.length > 0 ? (
              <div>
                <Route exact path='/auctions' component={AuctionPage} />
                <Route exact path='/create-auction' component={CreateAuctionPage} />
                <Route exact path='/contributors' component={ContributorsPage} />
                <Route
                  path='/auction/:id'
                  name='details'
                  component={AuctionDetailsPage}
                />
                <Route exact path='/setup/:token' component={AuctionSetupPage} />
                <Route exact path='/locker/:id' component={AuctionLockerPage} />
                <Route exact path='/cases/:id' component={AuctionCasePage} />
              </div>
            ) : (
              <div>
                <Connect />
              </div>
            )}
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
