import { useEffect, useState } from "react";
import {
  lockerContract as lockerContractInit
} from "./../contracts";

interface LockerInterface {
  LPTokenAddress: String;
  withdrawn: Boolean;
  auctionLockPeriod: Number;
  auctionOwner: String;
  LPTokenburned: Boolean;
}

const useLocker = (network, auctionAddress) => {
  const [loading, setLoading] = useState(true);
  const [locker, setLocker] = useState<LockerInterface>({});
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState("");

  const getLocker = async () => {
    setLoading(true);
    let lockerContract =await lockerContractInit(network);
    try {
      // let lockerAddress = await getLockerAddress(auctionAddress);
      let locker = await lockerContract.methods
        .auctionDetails(auctionAddress)
        .call();
      let amount = await lockerContract.methods
        .getLPAmount(auctionAddress)
        .call();
      setAmount(amount);
      setLocker(locker);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocker();
  }, [auctionAddress]);

  return {
    loading,
    locker,
    amount,
    error,
  };
};

export default useLocker;
